import React, { useEffect, useState } from "react";
import "./banner.scss";
import ImageBlock from "../acimage";
import SafeHTML from "../safehtml";
import ButtonLink from "../acbuttonlink";
import { useStaticQuery, graphql } from "gatsby"
import { getRelevantImageProp, getPagePathBySlug } from "../../assets/js/utils"
import NavigationLink from "../Header/navigationlink";
import useLocalStorage from "../Hooks";
import SupportDark from "../../assets/images/banner/support-dark.svg";
import StabilizationDark from "../../assets/images/banner/stabilization-dark.svg";
import AerospaceDark from "../../assets/images/banner/aerospace-dark.svg";
import MotionSimulationDark from "../../assets/images/banner/motion-simulation-dark.svg";
import SupportLight from "../../assets/images/banner/support-light.svg";
import StabilizationLight from "../../assets/images/banner/stabilization-light.svg";
import AerospaceLight from "../../assets/images/banner/aerospace-light.svg";
import MotionSimulationLight from "../../assets/images/banner/motion-simulation-light.svg";
import { CTAThemeTypes } from "../../assets/js/utils"
function Breadcrumb(props) {
    const { allSitePage } = useStaticQuery(
        graphql`
          query {
            allSitePage {
                nodes {
                  path
                  context{
                    slug
                  }
                }
              }
          }
        `);
    const [globalState, setGlobalState] = useLocalStorage("globalState", {});
    const [breadCrumbList, setBreadCrumbList] = useState(null);
    const onBreadcrumbClick = (page, cat) => {
        let navUrl = getPagePathBySlug(page.slug, allSitePage.nodes);
        if (cat)
            navUrl = `${navUrl}?c=${cat}`;
        window.location = navUrl;
    }
    useEffect(() => {
        if (props.currentPage && props.currentPage.parentPage && props.showBreadCrumb) {
            let page = props.currentPage;
            let _breadCrumbList = [];
            _breadCrumbList.push(<li key={page.slug}>{page.title}</li>);//add current page as text
            if (page.parentPage) {
                if (globalState && globalState.selectedCategory && globalState.contextualBreadcrumb) {
                    const sCategory = globalState.selectedCategory;
                    let pageCopy = { ...page.parentPage };
                    if (sCategory !== "All")
                        _breadCrumbList.push(<li key={sCategory}><button className="reset-btn" onClick={() => onBreadcrumbClick(pageCopy, sCategory)}>{sCategory}</button></li>);//add selected category into breadcrumb
                    setGlobalState({});//reset contextual breadcrumb & selected category
                }
                page = page.parentPage;
                while (page) {
                    let pageCopy = { ...page };
                    _breadCrumbList.push(<li key={page.slug}><button className="reset-btn" onClick={() => onBreadcrumbClick(pageCopy)}>{page.title}</button></li>);//add parent page as link
                    page = page.parentPage;
                }
            }
            setBreadCrumbList(<ul className="breadcrumbs">{_breadCrumbList.reverse()}</ul>);
        }
    }, []);
    return (<>
        {breadCrumbList}
    </>)
}

export default function GenericBanner(props) {
    const [triggerAnimationCss, setTriggerAnimationCss] = useState("");
    useEffect(() => {
        const timer = setTimeout(() => {
            if (props.isAnimationRequired)
                setTriggerAnimationCss("trigger-animation")
        }, 100);
        return () => clearTimeout(timer);

    }, [props.isAnimationRequired, triggerAnimationCss]);
    const getIllustrationSVG = () => {
        let svgImage = null;
        switch (props.patternType) {
            case "Support":
                svgImage = props.theme === "Light" ? SupportDark : SupportLight;
                break;
            case "Motion Simulation":
                svgImage = props.theme === "Light" ? MotionSimulationDark : MotionSimulationLight;
                break;
            case "Aerospace":
                svgImage = props.theme === "Light" ? AerospaceDark : AerospaceLight;
                break;
            case "Stabilization":
                svgImage = props.theme === "Light" ? StabilizationDark : StabilizationLight;
                break;
        }
        return svgImage ? <img className="illustration" src={`${svgImage}`}></img> : null;
    }
    const ctaButtonProps = {
        ...props.ctaButton,
        theme: props.theme === "Light" ? CTAThemeTypes["Style 1"] :  CTAThemeTypes["Style 2"],
    }
    return (
        <>
            <section className={`${triggerAnimationCss} ${props.cssClass}`}>
                <ImageBlock className="banner-badge" addImageAsBg={false} image={props.badge ? props.badge.file : null}></ImageBlock>
                {props.image && <div className="banner-bg-el">
                    <ImageBlock image={getRelevantImageProp(props, props.DEVICE_VIEW.IsMobile)} imgClassName="object-embed" addWrapper={false} addImageAsBg={true}></ImageBlock>
                </div>
                }
                <div className="container banner-container">
                    <Breadcrumb {...props}></Breadcrumb>
                    <div className="banner-inner">
                        <div className="banner-content">

                            {props.eyebrow && <h5 className="banner-eyebrow eyebrow"> {props.eyebrow}</h5>}

                            {props.title && <h1 className="banner-title">{props.title}</h1>}

                            {props.description && <div className="banner-desc">
                                <SafeHTML>
                                    {props.description}
                                </SafeHTML>
                            </div>}

                            {props.ctaButton &&
                                <ButtonLink {...ctaButtonProps} />
                            }
                            {props.children}
                        </div>
                    </div>

                </div>
                {(props.itemImage || props.patternType) && <div className="banner-object">
                    {getIllustrationSVG()}
                    {props.itemImage && <ImageBlock image={props.itemImage.file} className="object-embed" addWrapper={false} addImageAsBg={false}></ImageBlock>}
                </div>}
            </section>
            {props.showBreadCrumb && <NavigationLink currentPage={props.currentPage} DEVICE_VIEW={props.DEVICE_VIEW} sectionHeaders={props.sectionHeaders}></NavigationLink>}
        </>
    );
};
