import React from "react";
import { Link } from "gatsby";
import { isExternalUrl } from "../../src/assets/js/utils";
import ImageBlock from "./acimage"
import { CTAStyleTypes } from "../../src/assets/js/utils"

const getThemeClass = (props) => {
  return props.theme ? "btn-" + props.theme.toLowerCase().replace(/ /g, "-") : "btn-style-1";
}

function IconLinkButton(props) {
  const linkUrl = props.navigateTo && props.navigateTo.file ? props.navigateTo.file.url : props.url;
  return (
    <a rel="noopener noreferrer" className="hover-text-underline anchor-link" href={linkUrl} target={props.targetAtt}>
      {props.icon && <ImageBlock image={props.icon.file} className="object-embed img-icon" addWrapper={false}></ImageBlock>}
      <span>{props.text}</span>
    </a>

  )
}

function LinkButton(props) {
  const linkUrl = props.navigateTo && props.navigateTo.file ? props.navigateTo.file.url : props.url;
  return (
    <div className="btn-link-wrapper">
      <a className={`btn-link ${getThemeClass(props)}`} rel="noopener noreferrer" href={linkUrl} target={props.targetAtt}>
        <span>{props.text}</span> <i className="icon icon-arrow-solid-right"></i>
      </a>
    </div>
  )
}
function ButtonComponent(props) {
  const { style, openInNewWindow } = props;
  const linkUrl = props.navigateTo && props.navigateTo.file ? props.navigateTo.file.url : props.url;
  let isExternal = isExternalUrl(linkUrl);
  const targetAtt = openInNewWindow ? "_blank" : "_self";
  return <>
    {(CTAStyleTypes[style] === CTAStyleTypes.Default || style === CTAStyleTypes.Default) && (
      <IconLinkButton {...props} targetAtt={targetAtt}></IconLinkButton>
    )}

    {(CTAStyleTypes[style] === CTAStyleTypes.Secondary || style === CTAStyleTypes.Secondary) && (
      <LinkButton {...props} targetAtt={targetAtt}></LinkButton>
    )}

    {(CTAStyleTypes[style] === CTAStyleTypes.Primary || style === CTAStyleTypes.Primary) && !isExternal && (
      <Link
        className={`btn ${getThemeClass(props)}`}
        to={linkUrl}
        target={targetAtt}
      >
        {props.text}
        {props.theme === "Style 3" ? <></> : <span className="btn-icon"></span>}
      </Link>
    )}
    {(CTAStyleTypes[style] === CTAStyleTypes.Primary || style === CTAStyleTypes.Primary) && isExternal && (
      <a
        className={`btn ${getThemeClass(props)}`}
        href={linkUrl}
        target={targetAtt}
      >
        {props.text}
        {!props.theme === "Style 3" ? <></> : <span className="btn-icon"></span>}
      </a>
    )}
  </>
}
export default function acButtonLink(props) {
  if (!props.text)
    return null;
  return (
    <>
    <div className={props.theme === "Style 3" ? 'grey-container' : ''}>
      {props.addWrapper && <div className={`container ${props.theme === "Style 3" ? "button-wrapper-style-3" : "button-wrapper"}`}>
        <ButtonComponent {...props}></ButtonComponent>
      </div>}
      {!props.addWrapper &&
        <ButtonComponent {...props}></ButtonComponent>
      }
      </div>
    </>
  );
}
