import React from 'react'
import ImageBlock from "../acimage";
import PropTypes from 'prop-types';
import { getRelevantImageProp } from "../../assets/js/utils"


export default function LeftBadgeImage(props) {

    const { image, showBadge, DEVICE_VIEW } = props;


    return (
        <div className="bilateral-object">
            {showBadge && <div className="badge-icon">{showBadge}</div>}
            {image && <div className="align-middle d-lg-inline-block img-hover-zoom">
                <ImageBlock image={getRelevantImageProp(props, DEVICE_VIEW.IsMobile)} className="object-embed" addWrapper={false}></ImageBlock>
            </div>
            }
        </div>
    )
}

LeftBadgeImage.propTypes = {
    image: PropTypes.object,
    DEVICE_VIEW: PropTypes.object,
}