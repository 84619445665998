import React, { useState, useEffect, useRef } from "react";
import scrollToElement from 'scroll-to-element';
import "./navigationlink.scss";
export default function NavigationLink(props) {
    const FIRST_NAV_ITEM = { id: "navigation-link", text: props.currentPage.title };
    const [isSticky, setSticky] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState({});
    const [compState, setCompState] = useState(null);
    const ref = useRef(null);
    const adjustActiveSectionOnScroll = () => {
        const secHeaders = document.querySelectorAll(".comp-section-header");
        if (secHeaders.length > 0) {
            let navEl = document.querySelector('.header .navbar-brand');
            const SECTION_OFFSET = ((navEl.scrollHeight * 3) - 5);
            let wTop = window.scrollY;
            let matchFound = false;
            secHeaders.forEach(function (header) {
                let dvTop = header.offsetTop;
                if ((dvTop - SECTION_OFFSET) <= wTop) {
                    matchFound = true;
                    if (currentSection.id !== header.id) {
                        setCurrentSection({ id: header.id, text: header.innerText });
                    }
                }
            });
            if (!matchFound) {
                setCurrentSection(FIRST_NAV_ITEM);
            }
        }
    }
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 0);
            adjustActiveSectionOnScroll();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }
    const onNavigateCallback = (navItem) => {
        if (navItem !== null) {
            let navEl = document.querySelector('.header .navbar-brand');
            const SECTION_OFFSET = ((navEl.scrollHeight * 3) - 10);
            let elmToView = document.getElementById(`${navItem.id}`);
            if (elmToView) {
                scrollToElement(elmToView, {
                    offset: -SECTION_OFFSET,
                    duration: 200
                });
                setIsMenuOpen(false);//close menu after navigation
            }
        }
    }
    useEffect(() => {
        //load nav links
        let navLinks = [];
        if (props.currentPage) {
            navLinks.push(FIRST_NAV_ITEM);
        }
        const secHeaders = props.sectionHeaders
        if (secHeaders && secHeaders.length > 0) {
            secHeaders.forEach(function (header) {
                navLinks.push(header)
            })
            setCompState({ _navLinks: navLinks })
        }
    }, []);
    useEffect(() => {
        //set default nav links
        setCurrentSection(FIRST_NAV_ITEM);
    }, [compState]);
    if (compState === null)
        return null;
    return (
        <section id="navigation-link" className="navigation-link" ref={ref}>
            <div className={`navigation-link-inner container ${isSticky ? 'active' : ''} ${isMenuOpen ? 'menu-open' : ''}`}>
                <div className="navigation-wrap">
                    <div className="navigation-head">
                        <span className="navgigation-label">{currentSection.text}</span>
                        <button className="reset-btn navigation-toggle" onClick={() => toggleMenu()}><span className="sr-only">Toggle Menu</span></button>
                    </div>
                    <ul className="navigation-list">
                        {compState._navLinks &&
                            compState._navLinks.map((navItem, index) => {
                                if (!props.DEVICE_VIEW.IsDesktop && currentSection.id === navItem.id)
                                    return null;
                                return (
                                    <li key={index} id={`nav-${navItem.id}`} className={currentSection.id === navItem.id ? "active" : ""}>
                                        <button className="reset-btn" onClick={() => onNavigateCallback(navItem)}>
                                            {navItem.text}
                                        </button>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        </section>

    );
};