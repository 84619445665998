import React, { useState } from 'react';
import Form from '../Form/';
import { graphql, useStaticQuery } from "gatsby"
import OfficeLocationsAccordian from "../GeneralComponents/officelocationsaccordian";
import { ContactFormFields } from './json/form.js';
import { sendEmailWithTemplateData } from '../Services/emailTemplate'
import "./contactus.scss";
import ThankyouMessage from '../ThankYou/thankyou'
import StateOptions from "./json/states";
import CountryOptions from "./json/country";
import scrollToElement from 'scroll-to-element';
const ContactUs = (props) => {
    const [showThankYouMessage, setShowThankYouMessage] = useState(false)
    const { allContentfulTemplate, allContentfulContactFormSettingsDynamicFieldsJsonNode } = useStaticQuery(
        graphql`
          query {
            allContentfulTemplate {
                edges {
                  node {
                    id
                    subject
                    category
                    fromName
                    fromEmail
                    body {
                      json
                    }
                  }
                }
            }
            allContentfulContactFormSettingsDynamicFieldsJsonNode {
                edges {
                    node {
                        internal {
                            content
                        }
                    }
                }
            }
          }
        `);

    const dynamicFields = JSON.parse(allContentfulContactFormSettingsDynamicFieldsJsonNode.edges[0].node.internal.content)
    
    const divisionHandleChange = (e, formRef) => {
        contactFormFields.find(field => field.key === '__subDivision').hidden = true;
        contactFormFields.find(field => field.key === '__inquiryType').hidden = true;
        const selectedDivision = e.target.value
        const hasSubdivision = typeof dynamicFields.options[selectedDivision] === 'object'
        if (hasSubdivision) {
            const label = dynamicFields.options[selectedDivision].label
            const subDivisionOptions = Object.keys(dynamicFields.options[selectedDivision].options).map((key) => ({title: key, value: key}));
            Object.assign(
                contactFormFields.find(field => field.key === "__subDivision"),
                {
                    hidden: false,
                    label: label,
                    placeholder: `Select ${label}`,
                    options: subDivisionOptions,
                }
            )
            formRef.setFieldValue("__subDivision", "")
            formRef.setFieldValue("__inquiryType", "")
        }
    }
    
    const subDivisionHandleChange = (e, formRef) => {
        const selectedSubDivision = e.target.value;
        const selectedDivision = formRef.values.__division;
        const inquiryTypeOptions = Object.keys(dynamicFields.options[selectedDivision].options[selectedSubDivision]).map((key) => ({title: key, value: key}));
        Object.assign(
            contactFormFields.find(field => field.key === "__inquiryType"),
            {
                hidden: false,
                options: inquiryTypeOptions,
            }
        )
        formRef.setFieldValue("__inquiryType", "")
    }

    const countryHandleChange = (e, formRef) => {
        const selected = e.target.value;
        if (selected === 'USA') {
            contactFormFields.find(field => field.key === '__state').hidden = false;
        } else {
            contactFormFields.find(field => field.key === '__state').hidden = true;
        }
    }

    const contactFormHandlers = {
        "divisionHandleChange": divisionHandleChange,
        "subDivisionHandleChange": subDivisionHandleChange,
        "countryHandleChange": countryHandleChange,
        "stateOptions": StateOptions,
        "countryOptions": CountryOptions,
    }

    const removeIrrelevantValues = (values) => {
        if (values.__subDivision === "") {
            values["__subDivision"] = undefined;
            values["__inquiryType"] = undefined;
        }
        if (values.__country !== "US") {
            values["__state"] = undefined;
        }
        return values;
    }

    const getEmailRecipients = (values) => {
        const selectedDivision = values.__division;
        const selectedSubDivision = values.__subDivision ? values.__subDivision : null;
        const selectedInquiryType = values.__inquiryType;
        if (selectedSubDivision) {
            return dynamicFields.options[selectedDivision].options[selectedSubDivision][selectedInquiryType];
        }

        return dynamicFields.options[selectedDivision];
    }

    const setDivisionOptions = (contactFormFields, dynamicFields) => {
        const divisionOptions = Object.keys(dynamicFields.options).map((key) => ({title: key, value: key}));
        Object.assign(
            contactFormFields.find(field => field.key === "__division"),
            {
                label: dynamicFields.label,
                placeholder: `Select ${dynamicFields.label}`,
                options: divisionOptions,
            }
        )
    }

    const contactFormFields = ContactFormFields(contactFormHandlers)
    setDivisionOptions(contactFormFields, dynamicFields)
    
    return (
        <div className="contact-wrapper container">
            <div className="row">
                <div className="col-lg">
                    {!showThankYouMessage &&
                        <div className="contact-form-wrap">
                            <p className="form-desc">Please fill out the form below and one of our experts will get back to you as soon as possible.</p>
                            {/* BEGIN: FORM */}
                            {
                                <Form
                                    onSubmit={async (values) => {
                                        values = removeIrrelevantValues(values);
                                        const emailRecipients = getEmailRecipients(values);
                                        const emailResponse = await sendEmailWithTemplateData(emailRecipients, values, allContentfulTemplate.edges)

                                        if (emailResponse && emailResponse.success) {
                                            setShowThankYouMessage(true);
                                            scrollToElement(".contact-wrapper", {
                                                duration: 200
                                            });
                                        }
                                        else {
                                            alert(`Something went wrong. Please try again later. Internal Error: ${emailResponse ? emailResponse.message : "Unknown"}`);
                                            window.location = window.location;
                                        }
                                    }}
                                    fields={contactFormFields}
                                    DEVICE_VIEW={props.DEVICE_VIEW}
                                />
                            }
                            {/* END: FORM */}
                        </div>

                    }
                    {showThankYouMessage && <ThankyouMessage />}
                </div>
                <div className="col-lg-auto">
                    <OfficeLocationsAccordian
                        siteContacts={props.siteContacts} {...props}
                    ></OfficeLocationsAccordian>
                </div>
            </div>
        </div>
    );
};
export default ContactUs;