import React from 'react';
import "./card.scss";
import { Card } from 'react-bootstrap';
import MotionSimulationPatternSVG from "./svgs/motion-simulation"
import SupportPatternSVG from "./svgs/support"
import StabilizationPatternSVG from "./svgs/stabilization"
import AerospacePatternSVG from "./svgs/aerospace"
import { CardListTypes, getPagePathBySlug } from "../../assets/js/utils"
import ImageBlock from "../acimage";
import useLocalStorage from "../Hooks";
import Fade from 'react-reveal/Fade';
function GenericCard(props) {
	const [globalState, setGlobalState] = useLocalStorage("globalState", {});
	const PatternSVGs = {
		"Aerospace": <AerospacePatternSVG {...props}></AerospacePatternSVG>,
		"Motion Simulation": <MotionSimulationPatternSVG {...props}></MotionSimulationPatternSVG>,
		"Stabilization": <StabilizationPatternSVG {...props}></StabilizationPatternSVG>,
		"Support": <SupportPatternSVG {...props}></SupportPatternSVG>
	}
	const onCardClick = () => {
		if (globalState.selectedCategory !== props.ALL_FILTER_TEXT)
			globalState.contextualBreadcrumb = true;//render category in breadcrumb if current view is filtered
		setGlobalState(globalState);
		if (props.onCardClickCallback && props.data.navigateToPage != null) {
			props.onCardClickCallback(props.data);
		}
		else {
			const navUrl = props.data.navigateToPage != null ? getPagePathBySlug(props.data.navigateToPage.slug, props.allSitePage.nodes) : props.data.url;
			window.location = navUrl;
		}
	}
	const getCardLink = () => {
		let linkElement = null;
		linkElement = <button type="button" className="link-button stretched-link" onClick={() => { onCardClick() }}><span className="sr-only">More</span></button>

		return linkElement;
	}
	const getImage = () => {
		if (props.cardStyle === CardListTypes["Card Style 1"]) {
			//if style 1
			var svgImage = <div className="card-img-block">
				<div className={`card-svg-img ${getCssClass()}`}>
					{PatternSVGs[props.data.patternType]}
				</div>
			</div>;
			return svgImage;
		}
		else {
			return (
				<div className="card-img-block">
					{props.data.image &&
						<ImageBlock image={props.data.image.file} imgClassName="object-embed" addWrapper={false} addImageAsBg={false}></ImageBlock>
					}
				</div>
			)
		}
	}
	const getCssClass = () => {
		let cssClass = props.data.patternType ? props.data.patternType.toLowerCase().replace(/ /g, "-") : "";
		if (props.isActive) {
			cssClass = cssClass + " active";
		}
		if (!props.data.url && !props.data.navigateToPage) {
			cssClass = cssClass + " events-none";
		}
		return cssClass;
	}
	const getCardItem = () => {
		return <Card id={props.data.id} className={getCssClass()}>
			{getImage()}
			<Card.Body>
				{props.data.eyebrow && <Card.Subtitle className="eyebrow">{props.data.eyebrow}</Card.Subtitle>}
				<Card.Title className="has-top-line">{props.data.headline}</Card.Title>
				<Card.Text>
					{props.data.description && <span>{props.data.description.description}</span>}
					{props.showArrow && <i className="card-arrow icon icon-arrow-solid-right"></i>}
				</Card.Text>
				{getCardLink()}
			</Card.Body>
		</Card>
	}
	return (
		<>
			{props.withoutFade && getCardItem()}
			{!props.withoutFade && <Fade ssrFadeout>
				{getCardItem()}
			</Fade>}
		</>
	)
}

export { GenericCard }
