import React from "react"
export default function MotionSimulationPatternSVG(props){
  let imgUrl=null;
  if(props.data && props.data.image && props.data.image.file)
    imgUrl= props.data.image.file.url;
  return  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 352.2 352.2" space="preserve">
          <defs>
            <pattern id={`svg-${props.data.id}`} height="100%" width="100%" patternContentUnits="objectBoundingBox">
              <image href={imgUrl} preserveAspectRatio="none" width="1" height="1"></image>
            </pattern>
          </defs>
          <circle fill={`url(#svg-${props.data.id})`} cx="176.1" cy="176.1" r="175.4" />
          <g>
            <g>
              <path className="st1" fill="none" strokeWidth="1.5" strokeDasharray="2.5, 2, 2.5, 2, 2.5, 2" d="M105.6,105.6c-67.7,67.7-92,155.1-53.1,194.1c38.9,38.9,126.4,14.6,194.1-53.1
              s92-155.1,53.1-194.1C260.8,13.6,173.3,37.9,105.6,105.6z" />
              <path className="st2" fill="none" strokeWidth="1.5" d="M299.7,52.5L52.5,299.7" />
              <path className="st2" fill="none" strokeWidth="1.5" d="M52.5,52.5l247.1,247.1" />
              <path className="st2" fill="none" strokeWidth="1.5" d="M176.1,351.5C79.3,351.5,0.8,273,0.8,176.1S79.3,0.8,176.1,0.8c96.9,0,175.4,78.5,175.4,175.4 S273,351.5,176.1,351.5z" />
            </g>
            <g>
              <path className="st3" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M145.8,284.4c-7.5-5.2-15-11-22.3-17.2c-6.2-5.3-12.2-10.9-18.1-16.9
              C37.9,181.9,13.7,92.5,53.1,52.5C92.5,12.6,180.5,37.4,248,105.8c46.4,47,71.6,103.1,70.1,146.6c-0.7,19.8-6.9,36.9-19.2,49.4
              c-17.7,17.9-44.9,23.1-75.6,17.3c-3.4-0.6-11.4-3.6-14.8-4.5" />
              <polygon className="st4" points="217.9,308.2 210.4,315.1 210.6,325.9 192.9,306.3" />
            </g>
          </g>
        </svg>
} 