import { Field } from "formik";
import React from "react";

const rating = (props) => (
    <>
        <label class="form-label">{props.fieldMeta.label}</label>
        <fieldset class="rate">
            <Field class="sr-only" type="radio" id={"star5_" + props.fieldMeta.key} name={props.fieldMeta.key} value="5" />
            <label class="full" for={"star5_" + props.fieldMeta.key} title="5 stars"></label>
            
            <Field class="sr-only" type="radio" id={"star4_" + props.fieldMeta.key} name={props.fieldMeta.key} value="4" />
            <label class="full" for={"star4_" + props.fieldMeta.key} title="4 stars"></label>
            
            <Field class="sr-only" type="radio" id={"star3_" + props.fieldMeta.key} name={props.fieldMeta.key} value="3" />
            <label class="full" for={"star3_" + props.fieldMeta.key} title="3 stars"></label>
            
            <Field class="sr-only" type="radio" id={"star2_" + props.fieldMeta.key} name={props.fieldMeta.key} value="2" />
            <label class="full" for={"star2_" + props.fieldMeta.key} title="2 stars"></label>
            
            <Field class="sr-only" type="radio" id={"star1_" + props.fieldMeta.key} name={props.fieldMeta.key} value="1" />
            <label class="full" for={"star1_" + props.fieldMeta.key} title="1 star"></label>
        </fieldset>
    </>
);

export default rating;
