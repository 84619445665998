import React from "react";
import GenericBanner from "./genericbanner";
import Fade from 'react-reveal/Fade';
export default function GenericBannerContainer(props) {
    const BannerTypes = {
        "Interior Hero Style 1": "banner hero-banner style-1",
        "Interior Hero Style 2": "banner hero-banner style-2",
        "Interior Hero Style 3": "banner hero-banner style-3",
        "Prefooter": "banner banner-center banner-pre-footer",
        "Error": "trigger-animation banner hero-banner banner-center banner-error"
    }
    let bannerProps = { ...props };
    const getBannerCSSClass = () => {
        let cssClass = BannerTypes[props.bannerStyle];
        cssClass = `${cssClass} ${props.theme ? "theme-" + props.theme.toLowerCase() : ""} ${props.patternType ? props.patternType.toLowerCase().replace(/ /g, "-") : ""}`
        if (props.isOverlapAllowed && !bannerProps.isSlider)
            cssClass = cssClass + " overlap";
        return cssClass;
    }
    bannerProps.cssClass = getBannerCSSClass();
    bannerProps.showBreadCrumb = true;
    if (bannerProps.isSlider) {
        //if slider
        bannerProps.showBreadCrumb = bannerProps.isAnimationRequired = false;
        bannerProps.cssClass = bannerProps.cssClass + " hero-slider";
    }
    else {
        //if not slider
        bannerProps.isAnimationRequired = true;
        delete bannerProps.targetAudience;
        delete bannerProps.badge;
    }
    switch (BannerTypes[props.bannerStyle]) {
        case BannerTypes["Interior Hero Style 1"]:
            bannerProps.itemImage = bannerProps.image;
            delete bannerProps.image; delete bannerProps.description;
            break;
        case BannerTypes["Interior Hero Style 2"]:
            delete bannerProps.isOverlapAllowed; delete bannerProps.patternType;
            break;
        case BannerTypes["Interior Hero Style 3"]:
            delete bannerProps.eyebrow; delete bannerProps.mobileImage; delete bannerProps.image; delete bannerProps.description; delete bannerProps.indentedHeader;
            break;
        case BannerTypes["Prefooter"]:
            bannerProps.showBreadCrumb = false;
            delete bannerProps.eyebrow; delete bannerProps.patternType; delete bannerProps.isOverlapAllowed;
            break;
        case BannerTypes["Error"]:
            bannerProps.isAnimationRequired = bannerProps.showBreadCrumb = false;
            delete bannerProps.eyebrow; delete bannerProps.image; delete bannerProps.mobileImage; delete bannerProps.isOverlapAllowed; delete bannerProps.patternType;
            break;
        default:
            break;
    }
    return (
        <>
            {BannerTypes[props.bannerStyle] !== BannerTypes["Prefooter"] && <GenericBanner {...bannerProps}></GenericBanner>}
            {BannerTypes[props.bannerStyle] === BannerTypes["Prefooter"] && <Fade ssrFadeout><div className={bannerProps.__typename}><GenericBanner {...bannerProps}></GenericBanner></div></Fade>}
        </>
    );
};
