import React from "react"
export default function AerospaceSVG(props){
  let imgUrl=null;
  if(props.data && props.data.image && props.data.image.file)
    imgUrl= props.data.image.file.url;
  return  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 298.7 301" space="preserve">
  <defs>
    <pattern id={`svg-${props.data.id}`} height="100%" width="100%" patternContentUnits="objectBoundingBox">
      <image href={imgUrl} preserveAspectRatio="none" width="1" height="1"></image>
    </pattern>
  </defs>

  <path fill={`url(#svg-${props.data.id})`} d="M297.9,146.7c-0.1-18.1-10-34.9-26.7-48.8c1.7-21.4-3.5-40.1-16.3-52.9l0,0c-10.6-10.6-25.2-16-42-16.6
    c-4.4-0.2-9,0-13.7,0.5C185,11.2,167.6,0.8,148.9,0.8c-18.8-0.1-36.1,10.4-50.3,28.1C76,26.3,56.3,31.1,43.1,44.4
    c-12.6,12.5-17.6,31-15.8,52.2c-16.8,13.8-26.7,30.6-26.6,48.8C0.8,163.9,11.1,181,28.4,195c-3.8,24.8,0.9,46.6,15.4,61.1l0,0
    c13.4,13.4,33.2,18.4,55.8,16c14.2,17.7,31.5,28.1,50.2,28.1c18.8,0.1,36.1-10.4,50.3-28.1c22.6,2.6,42.3-2.2,55.5-15.5
    c14.2-14.2,18.7-35.8,14.8-60.4C287.8,182.4,298,165.3,297.9,146.7z"/>
  <g>
    <path fill="#010202" d="M44.4,256.2l-1.1-1.1L254.5,44l1.1,1.1L44.4,256.2z M256.1,255.7
      L43.8,43.4l-1.1,1.1l212.4,212.4L256.1,255.7z M256.2,256.9c33.8-33.8,13.4-109.1-45.5-168c-28.5-28.5-61.6-48.9-93.2-57.5
      c-31.8-8.7-58.4-4.4-74.8,12c-33.8,33.8-13.4,109.1,45.5,168c39.6,39.6,86.7,61.8,123.5,61.8C229.6,273.2,245.1,267.9,256.2,256.9z
      M43.8,44.4C77,11.2,151.4,31.6,209.7,89.9c58.3,58.3,78.7,132.7,45.5,165.9c-33.2,33.2-107.6,12.8-165.9-45.5
      C31,152,10.6,77.6,43.8,44.4z M150.6,299.8L149.8,0.3l-1.5,0l0.9,299.5L150.6,299.8z M193.2,280.7c26.7-26.7,43.3-76.7,43.1-130.3
      C236.1,67.4,196.9-0.3,149-0.4c0,0-0.1,0-0.1,0c-15.2,0-30.2,6.9-43.2,19.9C78.9,46.3,62.4,96.2,62.6,149.9
      c0.2,83,39.4,150.6,87.3,150.8c0,0,0.1,0,0.1,0C165.3,300.6,180.2,293.7,193.2,280.7z M149,1.1L149,1.1
      c47.1,0.1,85.6,67.1,85.8,149.3c0.2,53.3-16.2,102.8-42.7,129.3c-12.8,12.8-27.3,19.5-42.2,19.5c0,0-0.1,0-0.1,0
      C102.8,299,64.3,232,64.1,149.9C63.9,96.6,80.3,47.1,106.7,20.6c12.8-12.8,27.3-19.5,42.2-19.5C148.9,1.1,149,1.1,149,1.1z
      M211.5,212.2c5.1-5.1,9.9-10.4,14.5-15.7c2.6-3.1,5.2-6.3,7.7-9.5c0.3-0.3,0.2-0.8-0.1-1.1c-0.3-0.3-0.8-0.2-1.1,0.1
      c-2.5,3.2-5,6.4-7.6,9.5c-4.5,5.3-9.4,10.6-14.4,15.6C152.5,269,78,288.8,44.4,255.2c-33.6-33.6-13.8-108.1,44.1-166
      c38.6-38.6,86.3-61.7,124.4-60.4c17.1,0.6,31.4,6.2,41.5,16.3c16.2,16.2,20.6,42.3,12.4,73.4c-0.1,0.4,0.1,0.8,0.5,0.9
      c0.4,0.1,0.8-0.1,0.9-0.5c8.3-31.7,3.8-58.3-12.8-74.9c-10.4-10.4-25.1-16.2-42.6-16.8c-38.6-1.3-86.7,22-125.6,60.9
      C28.9,146.6,9.1,222,43.3,256.2C54.7,267.6,70.6,273,89,273C125.8,273,172.4,251.2,211.5,212.2z M262.6,133.1L277,116l-9,1.4
      l-6.7-5.4L262.6,133.1z"/>
    <path fill="#231F20" d="M19.1,187.1l1.1-1c0.4,0.4,0.8,0.8,1.2,1.2l0.6,0.6l-1,1.1c-0.4-0.4-0.8-0.8-1.2-1.2
      L19.1,187.1l1.1-1L19.1,187.1z M25.2,190.8l-1,1.1c-0.4-0.4-0.8-0.8-1.2-1.1l-0.6-0.6l1-1.1c0.4,0.4,0.8,0.7,1.2,1.1L25.2,190.8z
      M16,183.7l1.1-1c0.4,0.4,0.7,0.8,1.1,1.2l0.6,0.6l-1.1,1c-0.4-0.4-0.8-0.8-1.1-1.2L16,183.7l1.1-1L16,183.7z M28.6,193.8l-0.9,1.2
      c-0.4-0.4-0.9-0.7-1.3-1.1l-0.6-0.5l1-1.1c0.4,0.4,0.8,0.7,1.3,1.1L28.6,193.8z M13.1,180.2l1.2-0.9c0.3,0.4,0.7,0.9,1,1.3l0.5,0.6
      l-1.2,1c-0.4-0.4-0.7-0.9-1.1-1.3L13.1,180.2l1.2-0.9L13.1,180.2z M32.2,196.5l-0.9,1.2l-1-0.8l-1-0.8l0.9-1.2l1,0.8L32.2,196.5z
      M10.5,176.5l1.2-0.8c0.3,0.5,0.6,0.9,0.9,1.4l0.5,0.7l-1.2,0.9c-0.3-0.5-0.7-0.9-1-1.4L10.5,176.5l1.2-0.8L10.5,176.5z
      M35.7,199.1l-0.9,1.2l-1-0.7l-1-0.7l0.9-1.2l1,0.7L35.7,199.1z M8,172.7l1.3-0.8c0.3,0.5,0.6,0.9,0.9,1.4l0.4,0.7l-1.3,0.8
      c-0.3-0.5-0.6-1-0.9-1.4L8,172.7l1.3-0.8L8,172.7z M39.4,201.6l-0.8,1.3l-1.1-0.7l-1-0.7l0.8-1.2l1,0.7L39.4,201.6z M5.9,168.7
      l1.3-0.7c0.2,0.5,0.5,1,0.7,1.5l0.4,0.7L7,170.9c-0.3-0.5-0.5-1-0.8-1.5L5.9,168.7l1.3-0.7L5.9,168.7z M43.2,203.9l-0.8,1.3
      l-1.1-0.7l-1.1-0.7l0.8-1.3l1.1,0.7L43.2,203.9z M47.1,206.2l-0.7,1.3l-1.1-0.6l-1.1-0.6L45,205l1.1,0.6L47.1,206.2z M4,164.5
      l1.4-0.6c0.2,0.5,0.4,1,0.6,1.5l0.3,0.8L5,166.8c-0.2-0.5-0.5-1-0.7-1.5L4,164.5l1.4-0.6L4,164.5z M51.1,208.3l-0.7,1.3l-1.1-0.6
      l-1.1-0.6l0.7-1.3l1.1,0.6L51.1,208.3z M2.5,160.2l1.4-0.4c0.2,0.5,0.3,1,0.5,1.6l0.3,0.8l-1.4,0.5c-0.2-0.5-0.4-1.1-0.6-1.6
      L2.5,160.2l1.4-0.4L2.5,160.2z M55.1,210.3l-0.7,1.4l-1.1-0.6l-1.1-0.6l0.7-1.3l1.1,0.6L55.1,210.3z M1.3,155.8l1.5-0.3
      c0.1,0.5,0.2,1.1,0.4,1.6l0.2,0.8l-1.4,0.4c-0.1-0.5-0.3-1.1-0.4-1.6L1.3,155.8l1.5-0.3L1.3,155.8z M59.2,212.2l-0.6,1.4l-1.1-0.5
      l-1.1-0.5l0.6-1.4l1.1,0.5L59.2,212.2z M63.3,214l-0.6,1.4l-1.2-0.5l-1.2-0.5L61,213l1.1,0.5L63.3,214z M0.5,151.3l1.5-0.2
      c0.1,0.5,0.1,1.1,0.2,1.6l0.1,0.8l-1.5,0.3c-0.1-0.6-0.2-1.1-0.3-1.7L0.5,151.3l1.5-0.2L0.5,151.3z M67.4,215.7l-0.5,1.4l-1.2-0.5
      l-1.2-0.5l0.6-1.4l1.2,0.5L67.4,215.7z M71.6,217.2l-0.5,1.4l-1.2-0.4l-1.2-0.4l0.5-1.4l1.2,0.4L71.6,217.2z M0.1,146.7l1.5-0.1
      c0,0.5,0.1,1.1,0.1,1.6l0.1,0.8l-1.5,0.1c0-0.6-0.1-1.1-0.1-1.7L0.1,146.7l1.5-0.1L0.1,146.7z M3.7,148.6l0,1.5l-1.9,0l0-1.5
      L3.7,148.6z M75.9,218.7l-0.5,1.4l-1.2-0.4l-1.2-0.4l0.5-1.4l1.2,0.4L75.9,218.7z M0.2,142.2l1.5,0.1c0,0.5,0,1.1-0.1,1.6l0,0.8
      l-1.5,0c0-0.6,0-1.1,0-1.7L0.2,142.2l1.5,0.1L0.2,142.2z M8.2,148.6l0,1.5l-2.5,0l0-1.5L8.2,148.6z M80.1,220.1l-0.4,1.4l-1.2-0.4
      l-1.2-0.4l0.5-1.4l1.2,0.4L80.1,220.1z M84.5,221.4l-0.4,1.4l-1.2-0.4l-1.2-0.4l0.4-1.4l1.2,0.4L84.5,221.4z M12.7,148.6l0,1.5
      l-2.5,0l0-1.5L12.7,148.6z M0.6,137.6l1.5,0.2c-0.1,0.5-0.1,1.1-0.2,1.6l-0.1,0.8l-1.5-0.1c0.1-0.6,0.1-1.1,0.2-1.7L0.6,137.6
      l1.5,0.2L0.6,137.6z M88.8,222.6l-0.4,1.5l-1.2-0.3l-1.2-0.3l0.4-1.4l1.2,0.3L88.8,222.6z M17.2,148.6l0,1.5l-2.5,0l0-1.5
      L17.2,148.6z M1.5,133.1l1.5,0.3c-0.1,0.5-0.2,1.1-0.3,1.6l-0.2,0.8l-1.5-0.3c0.1-0.6,0.2-1.1,0.3-1.7L1.5,133.1l1.5,0.3L1.5,133.1
      z M93.2,223.7l-0.4,1.5l-1.2-0.3l-1.2-0.3l0.4-1.5l1.2,0.3L93.2,223.7z M97.5,224.7l-0.3,1.5l-1.2-0.3l-1.2-0.3l0.3-1.5l1.2,0.3
      L97.5,224.7z M21.7,148.6l0,1.5l-2.5,0l0-1.5L21.7,148.6z M2.7,128.7l1.4,0.5c-0.2,0.5-0.3,1-0.5,1.6l-0.2,0.8L2,131.2
      c0.1-0.5,0.3-1.1,0.5-1.6L2.7,128.7l1.4,0.5L2.7,128.7z M101.9,225.6l-0.3,1.5l-1.2-0.2l-1.2-0.3l0.3-1.5l1.2,0.3L101.9,225.6z
      M26.2,148.6l0,1.5l-2.5,0l0-1.5L26.2,148.6z M106.3,226.5l-0.3,1.5l-1.2-0.2l-1.2-0.2l0.3-1.5l1.2,0.2L106.3,226.5z M4.3,124.5
      l1.4,0.6c-0.2,0.5-0.4,1-0.6,1.5l-0.3,0.8l-1.4-0.5c0.2-0.5,0.4-1.1,0.6-1.6L4.3,124.5l1.4,0.6L4.3,124.5z M30.7,148.5l0,1.5
      l-2.5,0l0-1.5L30.7,148.5z M110.7,227.2l-0.2,1.5l-1.2-0.2l-1.2-0.2l0.3-1.5l1.2,0.2L110.7,227.2z M6.2,120.3l1.3,0.7
      c-0.3,0.5-0.5,1-0.7,1.5l-0.3,0.7l-1.4-0.6c0.2-0.5,0.5-1,0.7-1.5L6.2,120.3l1.3,0.7L6.2,120.3z M35.2,148.5l0,1.5l-2.5,0l0-1.5
      L35.2,148.5z M115.2,227.9l-0.2,1.5l-1.2-0.2l-1.2-0.2l0.2-1.5l1.2,0.2L115.2,227.9z M39.7,148.5l0,1.5l-2.5,0l0-1.5L39.7,148.5z
      M119.6,228.5l-0.2,1.5l-1.2-0.2l-1.2-0.2l0.2-1.5l1.2,0.2L119.6,228.5z M8.4,116.4l1.3,0.8c-0.3,0.5-0.6,0.9-0.8,1.4l-0.4,0.7
      l-1.3-0.7c0.3-0.5,0.6-1,0.8-1.5L8.4,116.4l1.3,0.8L8.4,116.4z M124,229l-0.2,1.5l-1.2-0.1l-1.2-0.1l0.2-1.5l1.2,0.1L124,229z
      M44.2,148.5l0,1.5l-2.5,0l0-1.5L44.2,148.5z M10.9,112.5l1.2,0.9c-0.3,0.5-0.6,0.9-0.9,1.4l-0.5,0.7l-1.3-0.8
      c0.3-0.5,0.6-0.9,0.9-1.4L10.9,112.5l1.2,0.9L10.9,112.5z M128.5,229.4l-0.1,1.5l-1.2-0.1l-1.2-0.1l0.1-1.5l1.2,0.1L128.5,229.4z
      M48.7,148.5l0,1.5l-2.5,0l0-1.5L48.7,148.5z M133,229.8l-0.1,1.5l-1.3-0.1l-1.2-0.1l0.1-1.5l1.2,0.1L133,229.8z M53.2,148.5l0,1.5
      l-2.5,0l0-1.5L53.2,148.5z M13.7,108.9l1.2,0.9c-0.3,0.4-0.7,0.9-1,1.3l-0.5,0.7l-1.2-0.9c0.3-0.4,0.7-0.9,1-1.3L13.7,108.9
      l1.2,0.9L13.7,108.9z M137.5,230l-0.1,1.5l-1.3-0.1l-1.3-0.1l0.1-1.5l1.2,0.1L137.5,230z M57.7,148.5l0,1.5l-2.5,0l0-1.5
      L57.7,148.5z M16.6,105.4l1.1,1c-0.4,0.4-0.7,0.8-1.1,1.2l-0.5,0.6l-1.1-1c0.4-0.4,0.7-0.8,1.1-1.3L16.6,105.4l1.1,1L16.6,105.4z
      M141.9,230.2l-0.1,1.5l-1.3,0l-1.3-0.1l0.1-1.5l1.2,0.1L141.9,230.2z M62.2,148.5l0,1.5l-2.5,0l0-1.5L62.2,148.5z M146.4,230.3
      l0,1.5l-1.3,0l-1.3,0l0-1.5l1.2,0L146.4,230.3z M19.8,102.1l1.1,1.1c-0.4,0.4-0.8,0.8-1.2,1.2l-0.6,0.6l-1.1-1
      c0.4-0.4,0.8-0.8,1.2-1.2L19.8,102.1l1.1,1.1L19.8,102.1z M66.7,148.5l0,1.5l-2.5,0l0-1.5L66.7,148.5z M71.2,148.5l0,1.5l-2.5,0
      l0-1.5L71.2,148.5z M23,99l1,1.1c-0.4,0.4-0.8,0.7-1.2,1.1l-0.6,0.6l-1-1.1c0.4-0.4,0.8-0.8,1.2-1.1L23,99z M156.8,230.3l0,1.5
      l-1.3,0l-1.3,0l0-1.5l1.3,0L156.8,230.3z M75.7,148.4l0,1.5l-2.5,0l0-1.5L75.7,148.4z M26.5,96l1,1.2c-0.4,0.4-0.8,0.7-1.3,1.1
      l-0.6,0.5l-1-1.1c0.4-0.4,0.8-0.7,1.3-1.1L26.5,96z M161.3,230.2l0.1,1.5l-1.3,0l-1.3,0l0-1.5l1.2,0L161.3,230.2z M80.2,148.4
      l0,1.5l-2.5,0l0-1.5L80.2,148.4z M165.8,230l0.1,1.5l-1.3,0.1l-1.3,0.1l-0.1-1.5l1.2-0.1L165.8,230z M84.7,148.4l0,1.5l-2.5,0
      l0-1.5L84.7,148.4z M30,93.2l0.9,1.2l-1,0.8l-1,0.8L28,94.7l1-0.8L30,93.2z M170.2,229.7l0.1,1.5l-1.2,0.1l-1.3,0.1l-0.1-1.5
      l1.2-0.1L170.2,229.7z M89.2,148.4l0,1.5l-2.5,0l0-1.5L89.2,148.4z M33.7,90.5l0.9,1.2l-1,0.7l-1,0.7L31.6,92l1-0.7L33.7,90.5z
      M93.7,148.4l0,1.5l-2.5,0l0-1.5L93.7,148.4z M174.7,229.3l0.1,1.5l-1.3,0.1l-1.3,0.1l-0.1-1.5l1.2-0.1L174.7,229.3z M37.4,88
      l0.8,1.3l-1,0.7l-1,0.7l-0.8-1.2l1-0.7L37.4,88z M98.2,148.4l0,1.5l-2.5,0l0-1.5L98.2,148.4z M179.2,228.8l0.2,1.5l-1.2,0.1
      l-1.2,0.1l-0.2-1.5l1.2-0.1L179.2,228.8z M102.7,148.4l0,1.5l-2.5,0l0-1.5L102.7,148.4z M183.6,228.3l0.2,1.5l-1.2,0.2l-1.2,0.2
      l-0.2-1.5l1.2-0.2L183.6,228.3z M41.3,85.6l0.8,1.3L41,87.5l-1.1,0.7l-0.8-1.3l1.1-0.7L41.3,85.6z M107.2,148.4l0,1.5l-2.5,0l0-1.5
      L107.2,148.4z M188.1,227.7l0.2,1.5l-1.2,0.2l-1.2,0.2l-0.2-1.5l1.2-0.2L188.1,227.7z M45.2,83.3l0.7,1.3l-1.1,0.6l-1.1,0.6
      L43,84.6l1.1-0.6L45.2,83.3z M111.7,148.4l0,1.5l-2.5,0l0-1.5L111.7,148.4z M192.5,227l0.2,1.5l-1.2,0.2l-1.2,0.2l-0.2-1.5l1.2-0.2
      L192.5,227z M116.2,148.3l0,1.5l-2.5,0l0-1.5L116.2,148.3z M49.2,81.2l0.7,1.3l-1.1,0.6l-1.1,0.6l-0.7-1.3l1.1-0.6L49.2,81.2z
      M196.9,226.2l0.3,1.5l-1.2,0.2l-1.2,0.2l-0.3-1.5l1.2-0.2L196.9,226.2z M120.7,148.3l0,1.5l-2.5,0l0-1.5L120.7,148.3z M53.2,79.2
      l0.7,1.4l-1.1,0.5l-1.1,0.6L51,80.3l1.1-0.6L53.2,79.2z M201.3,225.3l0.3,1.5l-1.2,0.3l-1.2,0.2l-0.3-1.5l1.2-0.2L201.3,225.3z
      M125.2,148.3l0,1.5l-2.5,0l0-1.5L125.2,148.3z M57.3,77.3l0.6,1.4l-1.1,0.5l-1.1,0.5L55,78.3l1.1-0.5L57.3,77.3z M205.7,224.3
      l0.3,1.5l-1.2,0.3l-1.2,0.3l-0.3-1.5l1.2-0.3L205.7,224.3z M129.7,148.3l0,1.5l-2.5,0l0-1.5L129.7,148.3z M134.2,148.3l0,1.5
      l-2.5,0l0-1.5L134.2,148.3z M61.5,75.5l0.6,1.4l-1.2,0.5l-1.1,0.5l-0.6-1.4l1.2-0.5L61.5,75.5z M210.1,223.3l0.4,1.5l-1.2,0.3
      l-1.2,0.3l-0.4-1.5l1.2-0.3L210.1,223.3z M138.7,148.3l0,1.5l-2.5,0l0-1.5L138.7,148.3z M65.7,73.8l0.5,1.4l-1.2,0.5l-1.2,0.5
      l-0.6-1.4l1.2-0.5L65.7,73.8z M214.4,222.1l0.4,1.4l-1.2,0.3l-1.2,0.3l-0.4-1.5l1.2-0.3L214.4,222.1z M143.2,148.3l0,1.5l-2.5,0
      l0-1.5L143.2,148.3z M69.9,72.2l0.5,1.4L69.3,74l-1.2,0.4l-0.5-1.4l1.2-0.4L69.9,72.2z M218.7,220.9l0.4,1.4l-1.2,0.4l-1.2,0.3
      l-0.4-1.4l1.2-0.3L218.7,220.9z M147.7,148.3l0,1.5l-2.5,0l0-1.5L147.7,148.3z M223,219.6l0.5,1.4l-1.2,0.4l-1.2,0.4l-0.4-1.4
      l1.2-0.4L223,219.6z M74.2,70.7l0.5,1.4l-1.2,0.4L72.3,73l-0.5-1.4l1.2-0.4L74.2,70.7z M152.2,148.3l0,1.5l-2.5,0l0-1.5
      L152.2,148.3z M156.7,148.3l0,1.5l-2.5,0l0-1.5L156.7,148.3z M227.2,218.1l0.5,1.4l-1.2,0.4l-1.2,0.4l-0.5-1.4l1.2-0.4L227.2,218.1
      z M78.5,69.4l0.4,1.4l-1.2,0.4l-1.2,0.4l-0.5-1.4l1.2-0.4L78.5,69.4z M161.2,148.2l0,1.5l-2.5,0l0-1.5L161.2,148.2z M82.8,68.1
      l0.4,1.4L82,69.9l-1.2,0.3l-0.4-1.4l1.2-0.4L82.8,68.1z M231.5,216.6l0.5,1.4l-1.2,0.4l-1.2,0.4l-0.5-1.4l1.2-0.4L231.5,216.6z
      M165.7,148.2l0,1.5l-2.5,0l0-1.5L165.7,148.2z M87.2,66.9l0.4,1.5l-1.2,0.3L85.2,69l-0.4-1.4l1.2-0.3L87.2,66.9z M235.6,215
      l0.6,1.4l-1.2,0.5l-1.2,0.5l-0.5-1.4l1.2-0.5L235.6,215z M170.2,148.2l0,1.5l-2.5,0l0-1.5L170.2,148.2z M91.6,65.8l0.3,1.5
      l-1.2,0.3l-1.2,0.3l-0.4-1.5l1.2-0.3L91.6,65.8z M239.8,213.2l0.6,1.4l-1.1,0.5l-1.2,0.5l-0.6-1.4l1.1-0.5L239.8,213.2z
      M174.7,148.2l0,1.5l-2.5,0l0-1.5L174.7,148.2z M179.2,148.2l0,1.5l-2.5,0l0-1.5L179.2,148.2z M96,64.8l0.3,1.5l-1.2,0.3l-1.2,0.3
      l-0.3-1.5l1.2-0.3L96,64.8z M243.9,211.4l0.6,1.4l-1.1,0.5l-1.1,0.5l-0.6-1.4l1.1-0.5L243.9,211.4z M183.7,148.2l0,1.5l-2.5,0
      l0-1.5L183.7,148.2z M100.4,63.9l0.3,1.5l-1.2,0.2l-1.2,0.2l-0.3-1.5l1.2-0.2L100.4,63.9z M247.9,209.4l0.7,1.3l-1.1,0.6l-1.1,0.6
      l-0.7-1.3l1.1-0.6L247.9,209.4z M188.2,148.2l0,1.5l-2.5,0l0-1.5L188.2,148.2z M104.8,63.1l0.3,1.5l-1.2,0.2l-1.2,0.2l-0.3-1.5
      l1.2-0.2L104.8,63.1z M192.7,148.2l0,1.5l-2.5,0l0-1.5L192.7,148.2z M251.9,207.3l0.7,1.3l-1.1,0.6l-1.1,0.6l-0.7-1.3l1.1-0.6
      L251.9,207.3z M109.3,62.4l0.2,1.5l-1.2,0.2l-1.2,0.2l-0.2-1.5l1.2-0.2L109.3,62.4z M197.2,148.2l0,1.5l-2.5,0l0-1.5L197.2,148.2z
      M255.8,205.1l0.8,1.3l-1.1,0.6l-1.1,0.6l-0.7-1.3l1.1-0.6L255.8,205.1z M113.8,61.7l0.2,1.5l-1.2,0.2l-1.2,0.2l-0.2-1.5l1.2-0.2
      L113.8,61.7z M201.7,148.2l0,1.5l-2.5,0l0-1.5L201.7,148.2z M259.5,202.8l0.8,1.3l-1.1,0.7l-1.1,0.7l-0.8-1.3l1.1-0.7L259.5,202.8z
      M118.3,61.2l0.2,1.5l-1.2,0.1l-1.2,0.2l-0.2-1.5l1.2-0.2L118.3,61.2z M206.2,148.1l0,1.5l-2.5,0l0-1.5L206.2,148.1z M122.7,60.7
      l0.1,1.5l-1.2,0.1l-1.2,0.1l-0.2-1.5l1.2-0.1L122.7,60.7z M210.7,148.1l0,1.5l-2.5,0l0-1.5L210.7,148.1z M263.3,200.3l0.8,1.2
      l-1,0.7L262,203l-0.8-1.3l1-0.7L263.3,200.3z M127.2,60.3l0.1,1.5l-1.2,0.1l-1.2,0.1l-0.1-1.5l1.2-0.1L127.2,60.3z M215.2,148.1
      l0,1.5l-2.5,0l0-1.5L215.2,148.1z M266.9,197.7l0.9,1.2l-1,0.7l-1,0.7l-0.9-1.2l1-0.7L266.9,197.7z M219.7,148.1l0,1.5l-2.5,0
      l0-1.5L219.7,148.1z M131.7,60l0.1,1.5l-1.2,0.1l-1.2,0.1l-0.1-1.5l1.2-0.1L131.7,60z M270.5,195l0.9,1.2c-0.4,0.3-0.9,0.7-1.3,1
      l-0.7,0.5l-0.9-1.2l1-0.8L270.5,195z M224.2,148.1l0,1.5l-2.5,0l0-1.5L224.2,148.1z M136.3,59.7l0.1,1.5l-1.2,0.1l-1.2,0.1
      l-0.1-1.5l1.2-0.1L136.3,59.7z M228.7,148.1l0,1.5l-2.5,0l0-1.5L228.7,148.1z M140.8,59.5l0,1.5l-1.2,0l-1.2,0l-0.1-1.5l1.2,0
      L140.8,59.5z M273.9,192.1l1,1.1c-0.4,0.4-0.8,0.7-1.3,1.1l-0.6,0.5l-1-1.1c0.4-0.4,0.8-0.7,1.3-1.1L273.9,192.1z M233.2,148.1
      l0,1.5l-2.5,0l0-1.5L233.2,148.1z M145.3,59.5l0,1.5l-1.2,0l-1.2,0l0-1.5l1.3,0L145.3,59.5z M277.1,189.1l1,1.1
      c-0.4,0.4-0.8,0.8-1.2,1.2l-0.6,0.6l-1-1.1c0.4-0.4,0.8-0.8,1.2-1.1L277.1,189.1z M237.7,148.1l0,1.5l-2.5,0l0-1.5L237.7,148.1z
      M149.8,59.5l0,1.5l-1.6,0l-0.9,0l0-1.5l1.8,0L149.8,59.5z M242.2,148.1l0,1.5l-2.5,0l0-1.5L242.2,148.1z M154.3,59.5l0,1.5l-1.2,0
      l-1.2,0l0-1.5l1.3,0L154.3,59.5z M280.3,185.9l1.1,1c-0.4,0.4-0.8,0.8-1.2,1.2l-0.6,0.6l-1.1-1.1c0.4-0.4,0.8-0.8,1.1-1.2
      L280.3,185.9l1.1,1L280.3,185.9z M246.7,148.1l0,1.5l-2.5,0l0-1.5L246.7,148.1z M158.8,59.7l-0.1,1.5l-1.2,0l-1.2,0l0-1.5l1.3,0
      L158.8,59.7z M283.2,182.5l1.2,1c-0.4,0.4-0.7,0.9-1.1,1.3l-0.6,0.6l-1.1-1c0.4-0.4,0.7-0.8,1.1-1.3L283.2,182.5l1.2,1L283.2,182.5
      z M251.2,148l0,1.5l-2.5,0l0-1.5L251.2,148z M163.3,59.9l-0.1,1.5l-1.2-0.1l-1.2-0.1l0.1-1.5l1.3,0.1L163.3,59.9z M286,179l1.2,0.9
      c-0.3,0.4-0.7,0.9-1,1.3l-0.5,0.7l-1.2-0.9c0.3-0.4,0.7-0.9,1-1.3L286,179l1.2,0.9L286,179z M255.7,148l0,1.5l-2.5,0l0-1.5
      L255.7,148z M167.8,60.2l-0.1,1.5l-1.2-0.1l-1.2-0.1l0.1-1.5l1.3,0.1L167.8,60.2z M172.3,60.6l-0.1,1.5l-1.2-0.1l-1.2-0.1l0.1-1.5
      l1.3,0.1L172.3,60.6z M260.2,148l0,1.5l-2.5,0l0-1.5L260.2,148z M288.5,175.3l1.3,0.8c-0.3,0.5-0.6,0.9-0.9,1.4l-0.5,0.7l-1.2-0.9
      c0.3-0.5,0.6-0.9,0.9-1.4L288.5,175.3l1.3,0.8L288.5,175.3z M176.8,61l-0.2,1.5l-1.2-0.1l-1.2-0.1l0.2-1.5l1.2,0.1L176.8,61z
      M264.7,148l0,1.5l-2.5,0l0-1.5L264.7,148z M181.3,61.6l-0.2,1.5l-1.2-0.2l-1.2-0.2l0.2-1.5l1.2,0.2L181.3,61.6z M290.7,171.4
      l1.3,0.7c-0.3,0.5-0.5,1-0.8,1.5l-0.4,0.7l-1.3-0.8c0.3-0.5,0.6-1,0.8-1.4L290.7,171.4l1.3,0.7L290.7,171.4z M269.2,148l0,1.5
      l-2.5,0l0-1.5L269.2,148z M185.7,62.2l-0.2,1.5l-1.2-0.2l-1.2-0.2l0.2-1.5l1.2,0.2L185.7,62.2z M273.7,148l0,1.5l-2.5,0l0-1.5
      L273.7,148z M292.7,167.4l1.4,0.6c-0.2,0.5-0.5,1-0.7,1.5l-0.4,0.8l-1.3-0.7c0.2-0.5,0.5-1,0.7-1.5L292.7,167.4l1.4,0.6
      L292.7,167.4z M190.2,62.9l-0.2,1.5l-1.2-0.2l-1.2-0.2l0.2-1.5l1.2,0.2L190.2,62.9z M278.2,148l0,1.5l-2.5,0l0-1.5L278.2,148z
      M194.6,63.7l-0.3,1.5l-1.2-0.2l-1.2-0.2l0.3-1.5l1.2,0.2L194.6,63.7z M294.3,163.3l1.4,0.5c-0.2,0.5-0.4,1.1-0.6,1.6l-0.3,0.8
      l-1.4-0.6c0.2-0.5,0.4-1,0.6-1.5L294.3,163.3l1.4,0.5L294.3,163.3z M199,64.6l-0.3,1.5l-1.2-0.3l-1.2-0.2l0.3-1.5l1.2,0.2L199,64.6
      z M282.7,148l0,1.5l-2.5,0l0-1.5L282.7,148z M295.7,159.1l1.5,0.4c-0.1,0.5-0.3,1.1-0.5,1.6l-0.2,0.8l-1.4-0.4
      c0.2-0.5,0.3-1.1,0.5-1.6L295.7,159.1l1.5,0.4L295.7,159.1z M203.4,65.6l-0.3,1.5l-1.2-0.3l-1.2-0.3L201,65l1.2,0.3L203.4,65.6z
      M287.2,148l0,1.5l-2.5,0l0-1.5L287.2,148z M207.8,66.6l-0.4,1.5l-1.2-0.3l-1.2-0.3l0.3-1.5l1.2,0.3L207.8,66.6z M296.6,154.8
      l1.5,0.3c-0.1,0.6-0.2,1.1-0.3,1.7l-0.2,0.8l-1.5-0.3c0.1-0.5,0.2-1.1,0.3-1.6L296.6,154.8l1.5,0.3L296.6,154.8z M291.7,148l0,1.5
      l-2.5,0l0-1.5L291.7,148z M212.2,67.8l-0.4,1.4l-1.2-0.3l-1.2-0.3l0.4-1.5l1.2,0.3L212.2,67.8z M216.6,69l-0.4,1.4l-1.2-0.3
      l-1.2-0.3l0.4-1.4l1.2,0.3L216.6,69z M297.1,150.4l1.5,0.1c0,0.6-0.1,1.1-0.2,1.7l-0.1,0.8l-1.5-0.2c0.1-0.5,0.1-1.1,0.2-1.6
      L297.1,150.4l1.5,0.1L297.1,150.4z M296.2,147.9l0,1.5l-2.5,0l0-1.5L296.2,147.9z M220.9,70.3l-0.5,1.4l-1.2-0.4l-1.2-0.4l0.4-1.4
      l1.2,0.4L220.9,70.3z M298.8,145.9l0,0.4c0,0.5,0,1,0,1.4l0,0.7l-1.5-0.1c0-0.5,0-0.9,0-1.4l0-0.7l0-0.4L298.8,145.9z M225.2,71.8
      l-0.5,1.4l-1.2-0.4l-1.2-0.4l0.5-1.4l1.2,0.4L225.2,71.8z M229.5,73.3l-0.5,1.4l-1.2-0.4l-1.2-0.4l0.5-1.4l1.2,0.4L229.5,73.3z
      M297,141.5l1.5-0.2c0.1,0.6,0.1,1.1,0.1,1.7l0.1,0.8l-1.5,0.1c0-0.5-0.1-1.1-0.1-1.6L297,141.5l1.5-0.2L297,141.5z M233.7,74.9
      l-0.6,1.4l-1.2-0.5l-1.2-0.5l0.5-1.4l1.2,0.5L233.7,74.9z M296.4,137.1l1.5-0.3c0.1,0.6,0.2,1.1,0.3,1.7l0.1,0.8l-1.5,0.2
      c-0.1-0.5-0.2-1.1-0.3-1.6L296.4,137.1l1.5-0.3L296.4,137.1z M237.9,76.7l-0.6,1.4l-1.1-0.5l-1.2-0.5l0.6-1.4l1.2,0.5L237.9,76.7z
      M242,78.5l-0.6,1.4l-1.1-0.5l-1.1-0.5l0.6-1.4l1.1,0.5L242,78.5z M295.3,132.8l1.4-0.4c0.2,0.5,0.3,1.1,0.4,1.6l0.2,0.8l-1.5,0.3
      c-0.1-0.5-0.3-1.1-0.4-1.6L295.3,132.8l1.4-0.4L295.3,132.8z M246.1,80.5l-0.7,1.3l-1.1-0.5l-1.1-0.5l0.6-1.4l1.1,0.5L246.1,80.5z
      M294,128.6l1.4-0.5c0.2,0.5,0.4,1.1,0.6,1.6l0.3,0.8l-1.4,0.5c-0.2-0.5-0.3-1-0.5-1.6L294,128.6l1.4-0.5L294,128.6z M250.1,82.5
      l-0.7,1.3l-1.1-0.6l-1.1-0.6l0.7-1.3l1.1,0.6L250.1,82.5z M254.1,84.7l-0.7,1.3l-1.1-0.6l-1.1-0.6l0.7-1.3l1.1,0.6L254.1,84.7z
      M292.2,124.5l1.4-0.6c0.2,0.5,0.5,1,0.7,1.5l0.3,0.8l-1.4,0.6c-0.2-0.5-0.4-1-0.7-1.5L292.2,124.5l1.4-0.6L292.2,124.5z M258,87
      l-0.8,1.3l-1.1-0.6L255,87l0.8-1.3l1.1,0.6L258,87z M290.2,120.5l1.3-0.7c0.3,0.5,0.5,1,0.8,1.5l0.4,0.7l-1.3,0.7
      c-0.2-0.5-0.5-1-0.8-1.5L290.2,120.5l1.3-0.7L290.2,120.5z M261.8,89.5l-0.8,1.2l-1-0.7l-1-0.7l0.8-1.3l1.1,0.7L261.8,89.5z
      M287.9,116.7l1.3-0.8c0.3,0.5,0.6,0.9,0.9,1.4l0.4,0.7l-1.3,0.8c-0.3-0.5-0.6-0.9-0.9-1.4L287.9,116.7l1.3-0.8L287.9,116.7z
      M265.5,92l-0.9,1.2l-1-0.7l-1-0.7l0.8-1.2l1,0.7L265.5,92z M285.3,113.1l1.2-0.9c0.3,0.5,0.7,0.9,1,1.4l0.5,0.7l-1.2,0.9
      c-0.3-0.4-0.6-0.9-1-1.3L285.3,113.1l1.2-0.9L285.3,113.1z M269.1,94.7l-0.9,1.2l-1-0.8l-1-0.7l0.9-1.2l1,0.8L269.1,94.7z
      M272.6,97.6l-1,1.1c-0.4-0.4-0.8-0.7-1.3-1.1l-0.6-0.5l0.9-1.2c0.4,0.4,0.9,0.7,1.3,1.1L272.6,97.6z M282.6,109.6l1.1-1
      c0.4,0.4,0.7,0.9,1.1,1.3l0.5,0.7l-1.2,0.9c-0.3-0.4-0.7-0.9-1-1.3L282.6,109.6l1.1-1L282.6,109.6z M276,100.6l-1,1.1
      c-0.4-0.4-0.8-0.7-1.2-1.1l-0.6-0.6l1-1.1c0.4,0.4,0.8,0.7,1.3,1.1L276,100.6z M279.6,106.2l1.1-1c0.4,0.4,0.8,0.8,1.1,1.2l0.6,0.6
      l-1.1,1c-0.4-0.4-0.7-0.8-1.1-1.2L279.6,106.2l1.1-1L279.6,106.2z M279.3,103.7l-1.1,1.1c-0.4-0.4-0.8-0.8-1.2-1.2l-0.6-0.6l1-1.1
      c0.4,0.4,0.8,0.8,1.2,1.2L279.3,103.7z M149.1,231.9l-0.7,0l0-1.5l1.4,0l1.2,0l1.2,0l0,1.5l-1.3,0L149.1,231.9z"/>
  </g>
</svg>
} 