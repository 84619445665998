import React from "react";

import { Link } from "gatsby";
import "./footer.scss";
import DesktopContactsView from "./desktopcontactsview";
import MobileContactsView from "./mobilecontactsview";
import logo from "../../../static/logo-white.svg";
export function ContactListItem(props) {
  let contact = props.contact;
  return <>
    {contact.phone && <p>Phone: <a href={"tel:" + contact.phone}>{contact.phone}</a></p>}
    {contact.fax && <p>Hotline: <a href={"tel:" + contact.fax}>{contact.fax}</a></p>}
    {contact.email && <p><a href={"mailto:" + contact.email}>{contact.email}</a></p>}
  </>;
}
export default function Footer(props) {
  const getNavigationLinks = () => {
    return (
      <div className="footer-widget">
        <ul className="footer-navbar">
          {props.siteSettings.mainMenuLinks &&
            props.siteSettings.mainMenuLinks.map((navItem, index) => {
              return (
                <li key={index}>
                  <Link to={`/${navItem.slug}`} activeClassName="active">
                    {navItem.title}
                  </Link>
                </li>
              );
            })}
          {props.siteSettings.hamburgerMenuLinks &&
            props.siteSettings.hamburgerMenuLinks.map((navItem, index) => {
              return (
                <li key={index}>
                  <Link to={`/${navItem.slug}`} activeClassName="active">
                    {navItem.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
  const getContactsView = () => {
    if (!props.DEVICE_VIEW.IsDesktop)
      return (
        <MobileContactsView
          siteContacts={props.siteSettings.siteContacts}
        ></MobileContactsView>
      );
    else
      return (
        <DesktopContactsView
          siteContacts={props.siteSettings.siteContacts}
        ></DesktopContactsView>
      );
  }

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-top-wrap">
            <div className="footer-left">
              <div className="footer-logo">
                <img src={logo} alt="Acutronic Logo" className="logo"></img>
              </div>
              <div className="footer-social">
                <h6>Get in Touch</h6>
                <a href={props.siteSettings.youtubeUrl} className="social-link" rel="noopener noreferrer" target="_blank">
                  <i className="icon icon-youtube"></i>
                </a>
                <a href={props.siteSettings.linkedInUrl} className="social-link" rel="noopener noreferrer" target="_blank">
                  <i className="icon icon-linkedIn"></i>
                </a>
                <a href={props.siteSettings.facebookUrl} className="social-link" rel="noopener noreferrer" target="_blank">
                  <i className="icon icon-facebook"></i>
                </a>
              </div>
            </div>
            <div className="footer-right">
              {getNavigationLinks()}
              {getContactsView()}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-wrap">
            <div className="footer-text">
              © {new Date().getFullYear()} Acutronic Holding AG. All Rights
              Reserved.
              </div>
            <div className="footer-links">
              <a href={props.siteSettings.termsOfUse}>Terms of Use</a>
              <a href={props.siteSettings.privacyPolicy}>Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );

}
