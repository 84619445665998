import React from "react";

export default function acImage(props) {
    const getImageHTML = () => {
        if (!props.image) return null;
        let img = (
          <img
            width={props.width}
            src={props.image.url}
            alt={props.image.title}
            className={props.className}
          />
        );
    
        if (props.addImageAsBg)
          img = (
            <div className="img-cover" style={ {backgroundImage: "url("+ props.image.url+")"} } >{img}</div>
          );
        if (props.addWrapper) {
          img = (
            <section className="img-section">
              <div className="container">{img}</div>
            </section>
          );
        }
        return img;
    }
    return (
        getImageHTML()
    );
};