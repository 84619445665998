const CustomerRatingFormFields = () => {
  return [
    {
      key: "__acutronicCompany",
      label: "Which Acutronic Company are you rating?",
      value: "",
      type: "select",
      options: [],
      validations: ["required"],
      styleClasses: ["col-md-6"],
    },
    {
      key: "__companyName",
      label: "Your Company",
      type: "text",
      value: "",
      placeholder: "Enter your company's name",
      validations: [""],
      styleClasses: ["col-md-6"],
    },
    {
      key: "__industry",
      label: "Your Industry",
      type: "select",
      value: "",
      options: [
        {
          value: "Automotive/Transportation/Mobility",
          title: "Automotive/Transportation/Mobility",
        },
        {
          value: "Aerospace/defense",
          title: "Aerospace/defense",
        },
        {
          value: "Robotics/Autonomous systems",
          title: "Robotics/Autonomous systems",
        },
        {
          value: "Consumer electronics",
          title: "Consumer electronics",
        },
        {
          value: "Services",
          title: "Services",
        },
        {
          value: "Multiple",
          title: "Multiple",
        },
        {
          value: "Other",
          title: "Other",
        },
      ],
      validations: ["required"],
      styleClasses: ["col-md-6"],
    },
    {
      key: "__position",
      label: "Your Position",
      type: "text",
      value: "",
      placeholder: "Enter your position",
      validations: ["required"],
      styleClasses: ["col-md-6"],
    },
    {
      key: "__name",
      label: "Your Name",
      type: "text",
      value: "",
      placeholder: "Enter your name",
      validations: [""],
      styleClasses: ["col-md-6"],
    },
    {
      key: "__email",
      label: "Your Email",
      type: "text",
      value: "",
      placeholder: "Enter your email address",
      validations: ["email"],
      styleClasses: ["col-md-6"],
    },

    /* BEGIN: Rating Fieldset */
    {
      key: "__ratingGroup",
      label: "How satisfied are you with Acutronic?",
      type: "ratingGroupFields",
      value: "",
      validations: ["required"],
      styleClasses: ["col-md-12"],
      fieldsNest: [
        {
          key: "__firstAnswer",
          type: "rating",
          validations: ["required"],
          label: "1. Overall satisfaction with Acutronic over the past years",
          styleClasses: ["col-md-12"],
        },
        {
          key: "__secondAnswer",
          type: "rating",
          validations: ["required"],
          label: "2. Acutronic's ability to solve problems",
          styleClasses: ["col-md-12"],
        },
        {
          key: "__thirdAnswer",
          type: "rating",
          validations: ["required"],
          label: "3. Clarity on scope of work / Scope of delivery",
          styleClasses: ["col-md-12"],
        },
        {
          key: "__fourthAnswer",
          type: "rating",
          validations: ["required"],
          label: "4. Technical Competence / Knowledge of Acutronic personnel",
          styleClasses: ["col-md-12"],
        },
        {
          key: "__fifthAnswer",
          type: "rating",
          validations: ["required"],
          label: "5. Service responsiveness, professionality, and courtesy",
          styleClasses: ["col-md-12"],
        },
        {
          key: "__sixthAnswer",
          type: "rating",
          validations: ["required"],
          label: "6. How likely are you to recommend Acutronic to a friend or colleague?",
          styleClasses: ["col-md-12"],
        },
      ]
    },
    /* END: Rating Fieldset */

    {
      key: "__review",
      label: "Review/Comments/Suggestions",
      type: "textarea",
      value: "",
      placeholder:
        "Use this box to write your personal review…\n\n• What do you find particularly positive/negative about us?\n• How can we improve?\n• What is important to you? What does your company value most in a vendor/partner? \n• Did Acutronic meet all your contractual requirements to your satisfaction in the last order/project?",
      validations: [""],
      styleClasses: ["col-md-12"],
    },
    {
      key: "__supportingImage",
      label: "Upload file? Choose a file using the input box below (<500kb)",
      type: "file",
      name: "file",
      placeholder: "No file chosen",
      id: "file",
      value: "",
      validations: [""],
      styleClasses: ["col-md-12"],
    },
  ];
};

export { CustomerRatingFormFields };
