import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { ContactListItem } from "./footer";
export default function MobileContactsView(props) {
  const [activePanel, setActivePanel] = useState(0);
  function handleAccordianClick(e, panelKey) {
    let el = e.target;
    if (el.classList.contains("active") === false)
      setActivePanel(panelKey);
    else
      setActivePanel(-1);
  }
  function getContactListItem(contact, index) {
    return (
      <div key={index} className="footer-widget">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={index}
            className={index === activePanel ? "active" : ""}
            onClick={event => {
              handleAccordianClick(event, index);
            }}
          >
            {contact.officeName}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index}>
            <Card.Body>
              <ContactListItem contact={contact} index={index}></ContactListItem>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    );
  }
  function getContactsList() {
    if (props.siteContacts) {
      return (
        <Accordion defaultActiveKey={activePanel}>
          {props.siteContacts.map((contact, index) => {
            return getContactListItem(contact, index);
          })}
        </Accordion>
      );
    }
    return null;
  }
  return getContactsList();
}
