export const sendEmail = async (payload) => {
    try {
        const TEST_EMAIL_RECIPIENT = process.env.GATSBY_TEST_EMAIL_RECIPIENT;
        const useTestEmail = typeof (TEST_EMAIL_RECIPIENT) === "string" && TEST_EMAIL_RECIPIENT.length > 0;

        if (useTestEmail) {
            payload.to = TEST_EMAIL_RECIPIENT.split(";");
        }

        const response = await fetch("/.netlify/functions/sendemail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        })

        if (!response.ok) {
            return { success: false, message: response.statusText };
        }
    } catch (e) {
        console.log(e);
    }
    return { success: true, message: "" };
}
