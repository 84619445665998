import React, { useState, useEffect, useRef } from 'react';
import { Accordion, Card } from "react-bootstrap";
import "./collapsiblefiltermenu.scss";
export default function CollapsibleFilterMenu(props) {
    const [isSticky, setSticky] = useState(false);
    const [isOpened, setIsOpened] = useState(!props.DEVICE_VIEW.IsMobile);
    function handleAccordianClick(e) {
        setIsOpened(!isOpened)
    }
    const menuRef = useRef(null);
    const handleScroll = () => {
        if (menuRef.current)
            setSticky(menuRef.current.getBoundingClientRect().top <= 0);
    };
    useEffect(() => {
        if (isSticky)
            setIsOpened(false);//when sticky applies then close menu
    }, [isSticky]);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);
    const onFilterCallackFunc = (cat) => {
        if (props.onFilterCallback) {
            props.onFilterCallback(cat);
            if (props.DEVICE_VIEW.IsMobile)
                setIsOpened(false);//auto close filter on mobile only
        }

    }
    return (
        <div className="collapsible-filter-menu" ref={menuRef}>
            <div className={`${isSticky ? "sticky" : ""}`}>
                <div className="container">
                    <Accordion defaultActiveKey="0" activeKey={isOpened ? "0" : "-1"}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            className={`${isOpened ? "opened" : "closed"}`}
                            onClick={event => {
                                handleAccordianClick(event);
                            }}>
                            {props.title}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <ul className="filter-menu-list">
                                {props.categoriesList && props.categoriesList.map(function (cat) {
                                    return <li key={cat} className={`${cat === props.selectedCategory ? "active" : ""}`}><button className="reset-btn" onClick={() => { onFilterCallackFunc(cat) }}>{cat}</button></li>
                                })}
                            </ul>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}
