
const ContactFormFields = (props) => {
    return [
        {
            "key": "__division",
            "value": "",
            "type": "select",
            "options": [],
            "validations": ["required"],
            "styleClasses": ["col-md-6"],
            "handleChange": props.divisionHandleChange,
        },
        {
            "key": "__subDivision",
            "value": "",
            "type": "select",
            "options": [],
            "validations": ["required"],
            "styleClasses": ["col-md-6"],
            "hidden": true,
            "handleChange": props.subDivisionHandleChange,
        },
        {
            "key": "__inquiryType",
            "value": "",
            "label": "Inquiry Type",
            "type": "select",
            "options": [],
            "placeholder": "Select Inquiry Type",
            "validations": ["required"],
            "styleClasses": ["col-md-6"],
            "hidden": true,
        },
        {
            "key": "__systemNumber",
            "label": "System Number",
            "type": "text",
            "value": "",
            "placeholder": "Enter the system number",
            "validations": [],
            "styleClasses": ["col-md-6"]
        },
        {
            "key": "__priority",
            "value": "",
            "label": "Priority",
            "type": "select",
            "options": [
                {
                    "value": "Low",
                    "title": "Low",
                },
                {
                    "value": "Medium",
                    "title": "Medium"
                },
                {
                    "value": "High",
                    "title": "High"
                }
            ],
            "placeholder": "Select Priority",
            "validations": ["required"],
            "styleClasses": ["col-md-6"],
        },
        {
            "key": "__companyName",
            "label": "Company Name",
            "type": "text",
            "value": "",
            "placeholder": "Enter your company's name",
            "validations": ["required"],
            "styleClasses": ["col-md-6"]
        },
        {
            "key": "__name",
            "label": "Your Name",
            "type": "text",
            "value": "",
            "placeholder": "Enter your name",
            "validations": ["required"],
            "styleClasses": ["col-md-6"]
        },
        {
            "key": "__email",
            "label": "Your Email",
            "type": "text",
            "value": "",
            "placeholder": "Enter your email address",
            "validations": ["required", "email"],
            "styleClasses": ["col-md-6"]
        },
        {
            "key": "__comment",
            "label": "Comment",
            "type": "textarea",
            "value": "",
            "placeholder": "Tell us about your inquiry.",
            "validations": ["required"],
            "styleClasses": ["col-md-12"]
        },
        {
            "key": "__address",
            "label": "Address",
            "type": "textarea",
            "value": "",
            "placeholder": "Enter your address",
            "validations": [],
            "styleClasses": ["col-md-12", "collapsible", "fld-address"]
        },
        {
            "key": "__country",
            "label": "Country",
            "type": "select",
            "options": props.countryOptions,
            "value": "",
            "placeholder": "-Select-",
            "validations": [],
            "styleClasses": ["col-md-12", "collapsible"],
            "handleChange": props.countryHandleChange
        },
        {
            "key": "__state",
            "label": "State",
            "type": "select",
            "options": props.stateOptions,
            "value": "",
            "placeholder": "-Select-",
            "validations": [],
            "styleClasses": ["col-md-12", "collapsible"],
            "hidden": true
        },
        {
            "key": "__city",
            "label": "City",
            "type": "text",
            "value": "",
            "placeholder": "Enter your city",
            "validations": [],
            "styleClasses": ["col-md-12", "collapsible"]
        },
        {
            "key": "__zip",
            "label": "ZIP Code",
            "type": "text",
            "value": "",
            "placeholder": "Enter ZIP code",
            "validations": ["number"],
            "styleClasses": ["col-md-12", "collapsible"]
        },
        {
            "key": "__phone",
            "label": "Phone",
            "type": "text",
            "value": "",
            "placeholder": "Enter your phone number",
            "validations": [],
            "styleClasses": ["col-md-12", "collapsible"]
        },
        {
            "key": "__fax",
            "label": "Fax",
            "type": "text",
            "value": "",
            "placeholder": "Enter your fax number",
            "validations": [],
            "styleClasses": ["col-md-12", "collapsible"]
        }
    ]
}

export { ContactFormFields };
