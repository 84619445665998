import React, { useState } from "react";
import Pagination from 'react-bootstrap/Pagination'
export default function SearchPagination(props) {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (activePage) => {
        setCurrentPage(activePage);
        if (props.onSearchPageChange) {
            let nextStartIndex = ((activePage - 1) * 10) + 1;//(page -1) * page size + 1
            props.onSearchPageChange(nextStartIndex);
        }
    }
    const getPagerHTML = () => {
        if (props.totalSearchResults < 11)
            return null;

        let pager = null;
        //default page size is 5
        let totalPages = Math.ceil(props.totalSearchResults / 10);
        if (totalPages > 5)
            totalPages = 5;
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage}>
                    <button className="reset-btn" onClick={() => { handlePaginationChange(number) }}>{number}</button>
                </Pagination.Item>,
            );
        }
        pager = <Pagination size="sm">{items}</Pagination>

        return pager;
    }
    return (getPagerHTML());

}
