import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import SafeHTML from "../safehtml"
import "./officelocationsaccordian.scss"
export default function OfficeLocationsAccordian(props) {
  const [activePanel, setActivePanel] = useState(0);
  function handleAccordianClick(e, panelKey) {
    let el = e.target;
    if (el.classList.contains("active") === false)
      setActivePanel(panelKey);
    else
      setActivePanel(-1);
  }
  function getContactListItem(contact, index) {
    return (
      <div key={index} className="contact-widget">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={index}
            className={index === activePanel ? "active" : ""}
            onClick={event => {
              handleAccordianClick(event, index);
            }}
          >
            {contact.officeName}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index}>
            <Card.Body>
              <div className="contact-location-item">
                {contact.address && <p><strong>Address:</strong> <span>{contact.address.address}</span></p>}
                {contact.phone && <p><strong>Phone:</strong> <a href={"tel:" + contact.phone}>{contact.phone}</a></p>}
                {contact.fax && <p><strong>Fax:</strong> <a href={"tel:" + contact.fax}>{contact.fax}</a></p>}
                {contact.email && <p><strong>Email:</strong><a href={"mailto:" + contact.email}>{contact.email}</a></p>}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    );
  }
  function getContactsList() {
    if (props.siteContacts) {
      return (
        <div className="contact-location-wrap">
          <div className="contact-location-head">
            <h5 className="contact-location-title">{props.title}</h5>
            <div className="contact-location-content">
              <SafeHTML>{props.description}</SafeHTML>
              <a className="rating" href="/customer-rating">Click here for our Customer Satisfaction Survey</a> 
            </div>
          </div>
          <div className="contact-location-body">
            <Accordion defaultActiveKey={activePanel}>
              {props.siteContacts.map((contact, index) => {
                return getContactListItem(contact, index+1);
              })}
            </Accordion>
          </div>
        </div>
      );
    }
    return null;
  }
  return getContactsList();
}
