import React from 'react';
import ButtonLink from "../acbuttonlink";
import '../BiLateralBlock/biLateralBlock.scss'
import './carddetailview.scss'
import SafeHTML from "../safehtml";
import CTAList from "../CTAList/ctaList";
import { CTAStyleTypes } from "../../assets/js/utils";
export default function CardDetailView(props) {
    const { components, learnMoreUrl, cardId } = props;
    let title, description, resourceHeader, resourceList, cta;
    components.forEach(comp => {
        if (comp.__typename === "ContentfulBiLateralBlock" && !description) {
            description = comp.bilateralTitle ? comp.bilateralTitle.title : "";
            resourceHeader = comp.resourceHeader;
            resourceList = comp.resourceList;
            cta = comp.cta;
        }
        if (comp.__typename === "ContentfulBanner" && !title) {
            title = comp.title;
        }
    });
    return (
        <>
            <section className={`card-detail-view`} id={cardId}>
                <div className="card-detail-view-inner">
                    {(props.onCloseCallback)
                        && <button type="button" className="btn bilateral-close" onClick={() => { props.onCloseCallback(); }}>
                            <i className="icon icon-close"></i> Close
                        </button>
                    }
                    <div className="container-fluid">
                        <div className={`row no-gutters`}>
                            <div className="col-lg">
                                <div className="bilateral-content has-top-line">
                                    {title && <h2 className="bilateral-title"> {title} </h2>}

                                    {description && <div className="bilateral-desc"><SafeHTML>
                                        {description}
                                    </SafeHTML>
                                    </div>
                                    }
                                    <div className="inline-btns bilateral-btns">
                                        {cta &&
                                            <ButtonLink {...cta} />
                                        }
                                        {learnMoreUrl &&
                                            <ButtonLink url={learnMoreUrl} text="Learn More" style={CTAStyleTypes.Secondary} theme="style-2" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto">
                                <CTAList resourceHeader={resourceHeader} resourceList={resourceList} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
