import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from "../acbuttonlink"


const renderResourceItem = (item, index) => {

    return (
        <li key={index}>
            <ButtonLink {...item} />
        </li>
    )
}

const renderResources = (resourceList) => {

    return (
        resourceList.map((item, index) => {
            return renderResourceItem(item, index, resourceList)
        })
    )
}

export default function CTAList(props) {
    const { resourceHeader, resourceList } = props;

    return (
        <>
            {resourceList && resourceList.length > 0 && <div className="bilateral-download">
                {resourceHeader && <h5 className="bilateral-list-title">{resourceHeader}</h5>}
                <ul className="bilateral-list">
                    {renderResources(resourceList)}
                </ul>
            </div>
            }
        </>
    )

}

CTAList.prototypes = {
    resourceHeader: PropTypes.string,
    resourceList: PropTypes.array
}


