/* eslint-disable react/no-danger */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from "react"
export default function SafeHTML({ children, className = '', el = 'span' }) {
  let props = {
    className,
  }
  if (children && children.json)
    return documentToReactComponents(children.json);

  if (typeof (children) === 'string') {
    props.dangerouslySetInnerHTML = { __html: children };
  } else {
    props.children = children;
  }
  return children ? React.createElement(
    el,
    props,
  ) : null;
}
