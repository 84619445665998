import React from "react";
import { Select, Input, Textarea, Rating, File } from "./controls";
import "./formfield.scss";

const formField = (props) => {
  const { field, formMeta} = props;

  return (
    !props.field.hidden && (
      <div
        className={[
          ...props.field.styleClasses,
          props.formMeta.touched.hasOwnProperty(props.field.key) &&
          props.formMeta.errors.hasOwnProperty(props.field.key)
            ? "error"
            : null,
        ].join(" ")}
      >
        <div className="form-group">
          <label className="form-label">
            {props.field.label}
            {props.field.validations.includes("required") ? "*" : null}
          </label>
          {/* BEGIN: SELECT */}
          {props.field.type === "select" && (
            <Select
              type={props.field.type}
              fieldMeta={props.field}
              formMeta={props.formMeta}
            />
          )}
          {/* END: SELECT */}
          {/* BEGIN: INPUT */}
          {((props.field.type === "text")) && (
            <Input
              type={props.field.type}
              fieldMeta={props.field}
              formMeta={props.formMeta}
            />
          )}
          {/* END: INPUT */}
          {/* BEGIN: FILE */}
          {props.field.type === "file" && (
            <File
              type={props.field.type}
              fieldMeta={props.field}
              formMeta={props.formMeta}
            />
          )}
          {/* END: FILE */}
          {/* BEGIN: TEXTAREA */}
          {props.field.type === "textarea" && (
            <Textarea
              type={props.field.type}
              fieldMeta={props.field}
              formMeta={props.formMeta}
            />
          )}
          {/* END: TEXTAREA */}
          {/* BEGIN: RATING */}
          {props.field.type === "ratingGroupFields" && (
            <div className="form-control form-control-lg rating-group-control">
              <div className="rating-group">
                <div className="rating-col-left">
                  Rate the following according to this scale: <br />
                  <br />
                  5= Excellent/Highly satisfied/Very likely
                  <br />
                  4= Above average/Satisfied/Likely
                  <br />
                  3= Average/Indifferent/Neutral
                  <br />
                  2= Below average/Dissatisfied/Unlikely
                  <br />
                  1= Poor/Very dissatisfied/Very unlikely
                </div>
                <div className="rating-col-right">
                  {props.field.fieldsNest.map((prop) => (
                    <Rating type={prop.type} fieldMeta={prop} />
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* END: RATING */}
          {formMeta.errors[field.key] && formMeta.touched[field.key] ? (
            <div className="text-danger">{formMeta.errors[field.key][0]}</div>
          ) : null}
        </div>
      </div>
    )
  );
};

export default formField;
