import React from "react";

const file = (props) => (
  <input
    type={props.type}
    className="form-control form-control-lg"
    name={props.fieldMeta.key}
    id={props.fieldMeta.id}
    onChange={(e) => {
      e.persist();
      e.target.value ? e.target.setAttribute("data-value", e.target.value) : e.target.removeAttribute("data-value");
      props.formMeta.setFieldValue(props.fieldMeta.key, e.target.files[0]);
    }}
    onBlur={props.formMeta.handleBlur}
    placeholder={props.fieldMeta.placeholder}
  />
);

export default file;
