import React from "react"
export default function SupportPatternSVG(props){
  let imgUrl=null;
  if(props.data && props.data.image && props.data.image.file)
    imgUrl= props.data.image.file.url;
  return  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 344.7 345.3" space="preserve">
  <defs>
    <pattern id={`svg-${props.data.id}`} height="100%" width="100%" patternContentUnits="objectBoundingBox">
      <image href={imgUrl} preserveAspectRatio="none" width="1" height="1"></image>
    </pattern>
  </defs>
  <path fill={`url(#svg-${props.data.id})`} d="M32.2,230.7c-2.9,26,2.8,48.7,18.1,64c12.5,12.5,30.2,19,51.3,19c4.1,0,8.3-0.2,12.6-0.7
c16.8,21,36.9,32.3,58.1,32.3c0,0,0,0,0,0c21.2,0,41.4-11.4,58.2-32.4c4.3,0.5,8.5,0.7,12.5,0.7c20.7,0,38.6-6.1,51.3-18.8
c15.3-15.3,21-37.9,18.1-63.9c20.9-17,32.1-37.3,32.1-58.6c0-16.8-7.2-33.5-20.9-48.1c-3.3-3.6-7.1-7-11.1-10.3
c2.8-26.4-3.5-48.4-18.3-63.2c-15.3-15.3-38-21-64-18.1C214.1,12,194,0,172.3,0c-21.6,0-41.6,12-58,32.4c-26.8-3-49.1,3.2-64.1,18.2
C35.4,65.4,29.2,87.4,32,113.9c-20.1,16.5-32,36.6-32,58.4C0,194,11.9,214.2,32.2,230.7z" />
  <g>
    <path className="st1" fill="none" strokeWidth="1.5" d="M73.5,172.6c0,94.9,44.3,171.9,98.9,171.9c54.6,0,98.9-77,98.9-171.9c0-94.9-44.3-171.9-98.9-171.9
    C117.7,0.8,73.5,77.7,73.5,172.6z" />
    <path className="st1" fill="none" strokeWidth="1.5" d="M293.9,51.1c38.6,38.6,15.5,124.3-51.6,191.5S89.4,332.8,50.8,294.2s-15.5-124.3,51.6-191.5
    S255.3,12.5,293.9,51.1z" />
    <path  className="st2" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M210.8,269.7c-4.6,0.6-9.2,1.1-14,1.5c-8,0.7-16.2,1-24.5,1c-94.8,0-171.6-44.7-171.6-99.9
    s76.8-99.9,171.6-99.9c65.2,0,121.8,21.1,150.9,52.3c13.2,14.2,20.7,30.4,20.7,47.6c0,27.5-19.1,52.4-50,70.5" />
    <path className="st1" fill="none" strokeWidth="1.5" d="M102.4,242.6c67.1,67.1,152.8,90.2,191.5,51.6c38.6-38.6,15.5-124.3-51.6-191.5 S89.4,12.5,50.8,51.1C12.2,89.7,35.3,175.4,102.4,242.6z" />
    <polygon className="st3" points="293.2,232.2 294.1,242.4 302.8,248.9 276.3,250.8" />
  </g>
</svg>
} 