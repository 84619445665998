import React from "react"
export default function getSVG(props) {
  let imgUrl = null;
  if (props.data && props.data.image && props.data.image.file)
    imgUrl = props.data.image.file.url;
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 359 356" space="preserve">
    <defs>
      <pattern id={`svg-${props.data.id}`} height="100%" width="100%" patternContentUnits="objectBoundingBox">
        <image href={imgUrl} preserveAspectRatio="none" width="1" height="1"></image>
      </pattern>
    </defs>
    <ellipse fill={`url(#svg-${props.data.id})`} cx="176.9" cy="177.1" rx="176.2" ry="176.3" />
    <g>
      <g>
        <path className="st0" fill="none" strokeWidth="1.5" strokeDasharray="2.5, 2, 2.5, 2, 2.5, 2" d="M177.1,0.3V353" />
        <path className="st0" fill="none" strokeWidth="1.5" strokeDasharray="2.5, 2, 2.5, 2, 2.5, 2" d="M301.6,299.8L50.7,52.8" />
        <path className="st0" fill="none" strokeWidth="1.5" strokeDasharray="2.5, 2, 2.5, 2, 2.5, 2" d="M51.6,300.8L303.1,53.1" />
        <path className="st0" fill="none" strokeWidth="1.5" strokeDasharray="2.5, 2, 2.5, 2, 2.5, 2" d="M0.5,177.1H353" />
      </g>
      <g>
        <path className="st1" fill="none" strokeWidth="1.5" d="M0.6,176.7C0.6,79.3,79.5,0.4,176.8,0.4S353,79.3,353,176.7c0,97.4-78.9,176.3-176.2,176.3 S0.6,274.1,0.6,176.7z" />
      </g>
      <g>
        <polyline className="st1" fill="none" strokeWidth="1.5" points="301.6,231.5 301.6,54.1 51.6,54.1 51.6,301.1 301.6,301.1" />
        <polygon className="st2" points="292.7,226.7 302.3,230.3 311.9,225.4 302,250" />
      </g>
    </g>
  </svg>
} 