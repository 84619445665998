import React, { Component } from "react"
import Header from "../Header/header"
import Footer from "../Footer/footer"

import "./layout.scss"

import "../../assets/sass/style.scss"
class layout extends Component {

  render() {
    const { children, siteSettings, DEVICE_VIEW } = this.props
    if (!siteSettings)
      return <div>{children}</div>

    return (
      <div className="main">
        <Header mainMenuLinks={siteSettings.mainMenuLinks} hamburgerMenuLinks={siteSettings.hamburgerMenuLinks} DEVICE_VIEW={DEVICE_VIEW} />
        <div className="content">{children}</div>
        <Footer className='footer' siteSettings={siteSettings} DEVICE_VIEW={DEVICE_VIEW} />
      </div>
    )
  }
}

export default layout
