import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { sendEmail } from "./sendEmail";
import { BLOCKS } from "@contentful/rich-text-types";

export const sendEmailWithTemplateData = async (
  staffEmailRecipients,
  data,
  body,
  isCustomerRating = false
) => {
  let response = null;

  if (body && body.length > 0 && staffEmailRecipients && data) {
    let template = isCustomerRating
      ? body.find((_body) => _body.node.category === "Customer Rating Rater")
      : body.find((_body) => _body.node.category === "Inquirer");

    response = await sendEmailByTemplate(template, staffEmailRecipients, data);

    if (response && response.success && body.length > 1) {
      //send second email
      template = isCustomerRating
        ? body.find((_body) => _body.node.category === "Customer Rating Staff")
        : body.find((_body) => _body.node.category === "Staff");

      response = await sendEmailByTemplate(
        template,
        staffEmailRecipients,
        data
      );
    }
  }

  return response;
};

const sendEmailByTemplate = async (template, staffEmailRecipients, data) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) =>
        `<img src="https:${fields.file["en-US"].url}" height="${fields.file["en-US"].details.image.height}" width="${fields.file["en-US"].details.image.width}" alt="${fields.title["en-US"]}"/>`,
    },
  };

  let emailBody = documentToHtmlString(template.node.body.json, options);

  const category = template.node.category;
  data = replaceNewlineWithLineBreak(data);
  data.to = getEmailRecipients(category, staffEmailRecipients, data.__email);
  emailBody = replacePlaceholders(emailBody, data);

  // Attachment process BEGIN
  let attachments = null;
  if(data.__supportingImage instanceof Blob) {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(getBase64StringFromDataURL(reader.result));
        reader.onerror = (error) => reject(error);
      });

    const getBase64StringFromDataURL = (dataURL) =>
      dataURL.replace("data:", "").replace(/^.+,/, "");

    const contentFile = await toBase64(data.__supportingImage);
    const type = data.__supportingImage.type.split("/")[1]
      ? data.__supportingImage.type.split("/")[1]
      : "json";

    // png, jpeg, pdf only
    attachments = [
      {
        content: `${contentFile}`,
        filename: data.__supportingImage.name,
        type: `application/${type}`,
        disposition: "attachment",
      },
    ];
  }
  // Attachment process END

  let subject = template.node.subject;
  subject = replacePlaceholders(subject, data);
  let payload = getEmailPayload(
    template,
    data.to,
    subject,
    emailBody,
    attachments
  );

  return await sendEmail(payload);
};

const replaceNewlineWithLineBreak = (data) => {
  for (const key of Object.keys(data)) {
    if (typeof data[key] === "string") {
      data[key] = data[key].replace(/\n/g, "<br>");
    }
  }
  return data;
};

const getEmailRecipients = (
  category,
  staffEmailRecipients,
  inquirerEmailRecipient
) => {
  if (category === "Staff" || category === "Customer Rating Staff") {
    return staffEmailRecipients.split(";");
  } else if (category === "Inquirer" || category === "Customer Rating Rater") {
    return inquirerEmailRecipient;
  } else {
    return "";
  }
};

const replaceTokenWithValueorDefault = (content, token, value) => {
  return content.replace(
    new RegExp(`{${token}}`, "g"),
    value === undefined || (value !== undefined && value.length === 0)
      ? "-"
      : value
  );
};
const replacePlaceholders = (emailBody, data) => {
  Object.keys(data).forEach(function(key) {
    emailBody = replaceTokenWithValueorDefault(
      emailBody,
      key.replace("__", ""),
      data[key]
    );
  });
  return emailBody;
};

const getEmailPayload = (
  template,
  emailRecipients,
  subject,
  emailBody,
  attachments
) => {
  
  let payload = {
    to: emailRecipients,
    from: {
      email: template.node.fromEmail
        ? template.node.fromEmail
        : "no-reply@acutronic.com",
      name: template.node.fromName ? template.node.fromName : "Acutronic",
    },
    subject: subject,
    html: emailBody,
    attachments,
  };

  Object.keys(payload).forEach(key => {
    if (payload[key] === null) {
      delete payload[key];
    }
  });

  return payload
};
