import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import logo from "../../../static/logo.svg";
import "./header.scss";
export default function Header(props) {
  const searchQueryRef = useRef(null);
  const [isSticky, setSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current)
      setSticky(ref.current.getBoundingClientRect().top <= 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  const redirectToSearch = () => {
    const sQuery = searchQueryRef.current.value;
    if (sQuery)
      window.location.href = "/search?query=" + sQuery;
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      redirectToSearch();
    }
  }
  return (
    <header className="header" id="header" ref={ref}>
      <div className={`header-inner${isSticky ? ' active' : ''}`}>
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Acutronic Logo" className="logo"></img>
          </Link>
          <div className={`navbar-container${isMenuOpen ? ' menu-open' : ''}`}>
            <ul className="main-navbar">
              {props.mainMenuLinks &&
                props.mainMenuLinks.map((navItem, index) => {
                  return (
                    <li key={index}>
                      <Link to={`/${navItem.slug}`} activeClassName="active">
                        {navItem.title}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <ul className="sub-navbar">
              {props.hamburgerMenuLinks &&
                props.hamburgerMenuLinks.map((navItem, index) => {
                  return (
                    <li key={index}>
                      <Link to={`/${navItem.slug}`} activeClassName="active">
                        {navItem.title}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className={`navbar-form ${isSearchOpen ? 'active' : ''}`}>
            <div className="form-group has-icon">
              <input type="text" name="query" id="query" ref={searchQueryRef} className="form-control form-control-lg"
                placeholder='Search'
                onKeyPress={handleKeyPress} />
              <button className="form-icon reset-btn icon-search" onClick={() => redirectToSearch()}></button>
            </div>
          </div>
          <button className="reset-btn navbar-search-toggler" title="Search" aria-label="Search toggle navigation" onClick={() => { setIsSearchOpen(!isSearchOpen); }}>
            <i className={`icon ${isSearchOpen ? 'icon-cross' : 'icon-search'}`}></i>
          </button>
          <button className={`navbar-toggler${isMenuOpen ? ' active' : ''}`} aria-label="Toggle navigation" onClick={toggleMenu}>
            <span></span>
          </button>
        </div>
      </div>
    </header >
  );
};