import React from "react";

import PropTypes from "prop-types";
import SafeHTML from "../safehtml";
import './videoBlock.scss';
import { isYoutubeVideo, getYoutubeId } from "../../assets/js/utils"

export default class VideoBlock extends React.Component {

    static propTypes = {
        url: PropTypes.string,
    };

    getVideoHTML = () => {

        const { url } = this.props
        const isYoutubeUrl = isYoutubeVideo(url)

        if (isYoutubeUrl === null) {
            return <SafeHTML>{url}</SafeHTML>
        }

        if (isYoutubeUrl) {
            const youtubeID = getYoutubeId(url)
            return <iframe className="embed-responsive-item" title="Youtube Video" width="100%" height="100%" src={`https://www.youtube.com/embed/${youtubeID}?autoplay=1&rel=0`} frameborder="0" allowfullscreen>
            </iframe>
        }
        else {
            return <video className="embed-responsive-item" width="100%" height="100%" className="object-embed" controls>
                <source src={this.props.uploadVideo.url} />
                Your browser does not support the video tag.
                    </video>

        }
    }
    render() {
        return (<div className="embed-responsive embed-responsive-16by9">{this.getVideoHTML()}</div>);
    }
}