import React, { useState } from 'react';
import Form from '../Form';
import { graphql, useStaticQuery } from "gatsby"
import { CustomerRatingFormFields } from './json/form.js';
import { sendEmailWithTemplateData } from '../Services/emailTemplate'
import "./customerRating.scss";
import ThankyouMessage from '../ThankYou/thankyou'
import scrollToElement from 'scroll-to-element';

const CustomerRating = (props) => {
    const [showThankYouMessage, setShowThankYouMessage] = useState(false)
    const { allContentfulTemplate, allContentfulCustomerRatingFormSettingsCustomerRatingJsonNode } = useStaticQuery(
        graphql`
            {
            allContentfulTemplate {
              edges {
                node {
                  id
                  subject
                  category
                  fromName
                  fromEmail
                  body {
                    json
                  }
                }
              }
            }
            allContentfulCustomerRatingFormSettingsCustomerRatingJsonNode {
              edges {
                node {
                  internal {
                    content
                  }
                }
              }
            }
          }
        `);

    const dynamicFields = JSON.parse(allContentfulCustomerRatingFormSettingsCustomerRatingJsonNode.edges[0].node.internal.content)

    const getEmailRecipients = (values) => {     
        return dynamicFields.options[values.__acutronicCompany];
    }

    const setAcutronicCompanyOptions = (customerRatingFormFields, dynamicFields) => {
        const acutronicCompany = Object.keys(dynamicFields.options).map((key) => ({title: key, value: key}));
        Object.assign(
            customerRatingFormFields.find(field => field.key === "__acutronicCompany"),
            {
                label: dynamicFields.label,
                placeholder: dynamicFields.placeholder,
                options: acutronicCompany,
            }
        )
    }

    const customerRatingFormFields = CustomerRatingFormFields()
    setAcutronicCompanyOptions(customerRatingFormFields, dynamicFields)
    
    return (
        <div className="customerRating-wrapper container">
            <div className="row">
                <div className="col-lg">
                    {!showThankYouMessage &&
                        <div className="customerRating-form-wrap">
                            <p className="form-desc">Use this form to rate Acutronic as a supplier/partner, for praise or complaints alike. Select the company you do business with, fill out the information, give us a star rating, and include comments and/or upload a file if desired.</p>
                            {/* BEGIN: FORM */}
                            {
                                <Form
                                    onSubmit={async (values) => {
                                        const emailRecipients = getEmailRecipients(values);
                                        const emailResponse = await sendEmailWithTemplateData(emailRecipients, values, allContentfulTemplate.edges, true);

                                        if (emailResponse && emailResponse.success) {
                                            setShowThankYouMessage(true);
                                            scrollToElement(".customerRating-wrapper", {
                                                duration: 200
                                            });
                                        }
                                        else {
                                            alert(`Something went wrong. Please try again later. Internal Error: ${emailResponse ? emailResponse.message : "Unknown"}`);
                                            window.location = window.location;
                                        }
                                    }}
                                    fields={customerRatingFormFields}
                                    DEVICE_VIEW={props.DEVICE_VIEW}
                                />
                            }
                            {/* END: FORM */}
                        </div>

                    }
                    {showThankYouMessage && <ThankyouMessage />}
                </div>
            </div>
        </div>
    );
};
export default CustomerRating;