import React, { useState, useEffect, useRef } from 'react';
import SafeHTML from '../safehtml';
import SearchPagination from './searchpagination';
import GenericBanner from '../GenericBanner/genericbanner';
import ButtonLink from "../acbuttonlink";
import { getParameterByName } from "../../assets/js/utils"
import Alert from 'react-bootstrap/Alert'
import scrollToElement from 'scroll-to-element';
import './sitesearch.scss'
export default function SiteSearch(props) {
    const searchQueryRef = useRef(null);
    const [searchResult, setSearchResult] = useState(null);
    let GOOGLE_SEARCH_API_URL = process.env.GATSBY_GOOGLE_SEARCH_API_URL ? process.env.GATSBY_GOOGLE_SEARCH_API_URL : "https://www.googleapis.com/customsearch/v1/siterestrict" || process.env.GATSBY_GOOGLE_SEARCH_API_URL;
    let GOOGLE_SEARCH_API_KEY = process.env.GATSBY_GOOGLE_SEARCH_API_KEY ? process.env.GATSBY_GOOGLE_SEARCH_API_KEY : "AIzaSyBFIPxCTVI0pM8Fxna8oV2IErcwI_251GE";
    let GOOGLE_SEARCH_ENGINE_ID = process.env.GATSBY_GOOGLE_SEARCH_ENGINE_ID ? process.env.GATSBY_GOOGLE_SEARCH_ENGINE_ID : "015986212861449405904:rftx2rmrx94";
    const getSearchResults = (startIndex, resetResultCount) => {
        const sQuery = searchQueryRef.current.value;
        if (!sQuery)
            return null;
        fetch(`${GOOGLE_SEARCH_API_URL}?key=${GOOGLE_SEARCH_API_KEY}&cx=${GOOGLE_SEARCH_ENGINE_ID}&start=${startIndex}&q=${encodeURIComponent(sQuery)}`)
            .then(response => {
                return response.json();
            }).then(data => {
                setSearchResult({
                    results: data,
                    resultCount: !resetResultCount && searchResult ? searchResult.resultCount : data.searchInformation.formattedTotalResults
                });
            }).catch(function (ex) {
                setSearchResult({
                    results: { error: ex },
                    resultCount: 0
                });
            });
    }
    const performSearch = () => {
        getSearchResults(1, true);//start search from first page and reset search result count
    }
    const onPageChange = (nextStartIndex) => {
        getSearchResults(nextStartIndex, false);
        scrollToElement(`.banner`, {
            ease: 'out-bounce',
            duration: 200
        });
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            performSearch();
        }
    }
    const getSearchResultComponent = () => {
        if (!searchResult || !searchResult.results)
            return null;
        if (searchResult.results.error) {
            return <Alert variant="danger">
                <p>We're sorry an error has occurred while performing search.</p>
            </Alert>
        }
        return <>
            {searchResult.results.items &&
                <>
                    <ul className="search-result">
                        {searchResult.results.items.map((data, index) => {
                            return <li key={data.cacheId}>
                                <h5 className="search-result-title"><a href={data.link}><SafeHTML>{data.htmlTitle}</SafeHTML></a></h5>
                                <cite className="search-result-link">{data.link}</cite>
                                <p className="search-result-desc">
                                    <SafeHTML>{data.htmlSnippet}</SafeHTML>
                                    <ButtonLink style="button-link" url={data.link} text="Read More" ></ButtonLink>
                                </p>
                            </li>
                        })
                        }
                    </ul>
                    <SearchPagination totalSearchResults={Number(searchResult.resultCount.replace(/,/g, ''))} onSearchPageChange={onPageChange}></SearchPagination>
                </>
            }
        </>
    }
    useEffect(() => {
        let sQuery = getParameterByName('query');//search on load
        if (sQuery) {
            searchQueryRef.current.value = sQuery;
            performSearch();
        }
    }, [])
    return (
        <>
            <div className="search-wrapper">
                <GenericBanner cssClass="trigger-animation banner hero-banner banner-center style-3 banner-search theme-dark" title={props.title} theme="Dark" patternType="Motion Simulation">
                    <div className="form-group has-icon">
                        <input type="text" name="query" id="query" ref={searchQueryRef} className="form-control form-control-lg"
                            placeholder='Search'
                            onKeyPress={handleKeyPress} />
                        <button className="form-icon reset-btn icon-search" onClick={() => performSearch()}></button>
                    </div>
                    {searchResult && <p className="search-count-text">{searchResult.resultCount} results</p>}
                </GenericBanner>
                <section className="search-result-section">
                    <div className="container">
                        <div className="search-result-wrap">
                            {getSearchResultComponent()}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
