import React from "react"
import { ContactListItem } from "./footer";
export default function DesktopContactsView(props) {
    function getContactItem(contact, index) {
        return <li key={index}><h6 className="contact-info-title">{contact.officeName}</h6><ContactListItem contact={contact} index={index}></ContactListItem></li>;
    }
    function getContactsList() {
        if (props.siteContacts) {
            let firstColumnContacts = [], secondColumnContacts = [];
            props.siteContacts.map((contact, index) => {
                if ((index + 1) % 2 === 1) {
                    //odd items
                    firstColumnContacts.push(getContactItem(contact, index));
                }
                else {
                    //even items
                    secondColumnContacts.push(getContactItem(contact, index));
                }
                return null;
            })

            let firstColum = <div key="firstCol" className="footer-widget"><ul className="contact-info-wrap">
                {firstColumnContacts}
            </ul></div>;
            let secondColum = <div key="secondCol" className="footer-widget"><ul className="contact-info-wrap">
                {secondColumnContacts}
            </ul></div>;
            return [firstColum, secondColum]
        }
        return null;
    }
    return (
        getContactsList()
    )
}
