import React from 'react';
import ButtonLink from "../acbuttonlink";
import { CTAStyleTypes } from "../../../src/assets/js/utils";
import "./thankyou.scss"

export default function Thankyou(props) {
    return (
        <div className="response-msg">
            <h1 className="has-top-line response-title">Thank You</h1>
            <p className="response-desc">Thank you for your inquiry! We will get back to you shortly with a response.</p>
            <ButtonLink url="/" text="Back to Homepage" style={CTAStyleTypes.Primary} theme="style-2"/>

        </div>
    )

}

