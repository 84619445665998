import React, { useState } from "react";
import { Formik } from "formik";
import FormField from "./formfield";
import scrollToElement from "scroll-to-element";
import { Spinner } from "react-bootstrap";
/**
 * @name setValidationMsg
 * @param {string} msg Validation msg that needs to be added.
 * @param {object} errorObj Object in which all error are residing.
 * @param {string} valueKey Key of that value that needs to be checked.
 * @desc Sets validation msg in object.
 * @return {object} Object that can be replaced in front of that value.
 */
const setValidationMsg = (validationMsg, valueKey, errorObj) => {
  if (errorObj.hasOwnProperty(valueKey)) {
    errorObj[valueKey].push(validationMsg);
  } else {
    errorObj[valueKey] = [validationMsg];
  }
};

/**
 * @name validateFields
 * @param {object} values
 * @desc Validate's values based on custom conditions and cases.
 * @return {object} errors
 */
const validateFields = (
  values,
  props,
  setFocus,
  updateSetFocus,
  isSubmitClicked
) => {
  const errors = {};
  if (isSubmitClicked) {
    Object.keys(values)
      // valueKey: key of current index iteration.
      .forEach((valueKey, index) => {
        const field = props.fields.find((_field) => _field.key === valueKey);
        if (!field || field?.hidden) {
          // Skipping validation for hidden fields, since they will not be passed during form submission
          return;
        }
        if (field.key === "__ratingGroup") {
          field.fieldsNest.forEach((fieldsNestValue) => {
            if (!values[fieldsNestValue?.key]) {
              setValidationMsg(`${field.label} is required`, valueKey, errors);
            }
          });
        } else {
          field.validations
            // validation: string of validation type
            .forEach((validation) => {
              switch (validation) {
                case "required":
                  if (!values[valueKey]) {
                    setValidationMsg(
                      `${field.label} is required`,
                      valueKey,
                      errors
                    );
                  }
                  break;
                case "email":
                  if (
                    values[valueKey] &&
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values[valueKey]
                    )
                  )
                    setValidationMsg(
                      `${field.label} is invalid`,
                      valueKey,
                      errors
                    );
                  break;
                case "number":
                  if (
                    values[valueKey] &&
                    !/^\d{5}(-\d{4})?(?!-)$/.test(values[valueKey])
                  )
                    setValidationMsg(
                      `${field.label} is invalid`,
                      valueKey,
                      errors
                    );
                  break;
                case "file":
                  const MIN_SIZE = 1;
                  const MAX_SIZE = 500;
                  // image/png, image/jpeg, application/pdf

                  const validTypes = ["JPG", "PNG", "PDF", "jpg", "png", "pdf"];

                  if (
                    !validTypes.includes(values[valueKey].type.split("/")[1])
                  ) {
                    setValidationMsg(
                      "File type must be JPG, PNG, PDF",
                      valueKey,
                      errors
                    );
                  }

                  if (
                    (values[valueKey].size / 1024) < MIN_SIZE ||
                    (values[valueKey].size / 1024) > MAX_SIZE
                  ) {
                    setValidationMsg(
                      "File size must be < 500 kb",
                      valueKey,
                      errors
                    );
                  }
                  break;
                default:
              }
            });
        }
      });
  }
  if (errors && setFocus) {
    const fieldId = Object.keys(errors)[0];
    const elm = document.getElementById(fieldId);
    const navEl = document.querySelector(".header");
    if (elm) {
      scrollToElement(elm, {
        duration: 200,
        offset: -(navEl.scrollHeight + 50),
      });
      elm.focus();
      updateSetFocus(false); //reset to false for not focusing unless user hits submit again
    }
  }
  return errors;
};

/**
 * @name getInitialValues
 * @desc Creates a payload for initial values that can be further used
 * in conjunction with formik.
 * @return {object} initialValues
 */
const getInitialValues = (props) => {
  const initialValues = {};
  props.fields.forEach(
    // SETTING FIELD VALUES WITH RESPECT TO ITS KEY
    // IN OBJECT
    (field) => (initialValues[field.key] = field.value)
  );
  return initialValues;
};

const Form = (props) => {
  const [showAdditionalFields, setShowAdditionalFields] = useState(
    !props.DEVICE_VIEW.IsMobile
  );
  const [setFocus, updateSetFocus] = useState(false);
  const [isSubmitClicked, updateIsSubmitClicked] = useState(false);
  const getFieldHiddenValue = (field) => {
    if (field.styleClasses.includes("collapsible")) {
      if (showAdditionalFields) {
        if (field.key !== "__state") {
          return false;
        }
      } else {
        return true;
      }
    }
    return field.hidden;
  };

  return (
    <Formik
      initialValues={getInitialValues(props)}
      validate={(values) =>
        validateFields(values, props, setFocus, updateSetFocus, isSubmitClicked)
      }
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
    >
      {(formMeta) => {
        return (
          <div className="contact-form">
            <form className="row" onSubmit={formMeta.handleSubmit}>
              {props.fields.map((field, index) => {
                field.hidden = getFieldHiddenValue(field);
                return (
                  <React.Fragment key={index}>
                    <FormField field={field} formMeta={formMeta} />
                    {props.DEVICE_VIEW.IsMobile && field.key === "__comment" && (
                      <div className="col-md-12 btn-additional-field-wrapper">
                        <button
                          type="button"
                          className="btn btn-light-blue"
                          aria-label="Toggle additional fields"
                          onClick={() => {
                            setShowAdditionalFields(!showAdditionalFields);
                          }}
                        >
                          Tell us more about yourself{" "}
                          <span
                            className={`icon ${
                              showAdditionalFields
                                ? "icon-arrow-solid-up"
                                : "icon-arrow-solid-down"
                            }`}
                          ></span>
                        </button>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
              <div className="col-lg-12">
                <div className="form-footer">
                  <button
                    type="submit"
                    className="btn"
                    onClick={() => {
                      updateSetFocus(true);
                      updateIsSubmitClicked(true);
                    }}
                    disabled={formMeta.isSubmitting}
                  >
                    {!formMeta.isSubmitting && <>Send Form</>}
                    {formMeta.isSubmitting && (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Submitting ...
                      </>
                    )}
                    <span className="btn-icon"></span>
                  </button>
                  <span className="form-msg">*Required Fields</span>
                </div>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Form;
